import axiosApi from '../../kits/axiosApi'
const _ = require('lodash')

// initial state
const state = {
    is_loading: true,
    radioStr: '',
    otherOrders: [],
}

// getters
const getters = {
    get_radioStr(state) {
        return state.radioStr
    },
    get_otherOrders(state) {
        return state.otherOrders
    },
}

// actions
const actions = {
    createOrder({ commit }, params) {
        return new Promise((resolve, reject) => {
            axiosApi
                .post('/weapp/teamup/order/create_teamup_order', { params })
                .then((res) => {
                    let data = res.data.data
                    resolve(data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    queryOrder({ commit }, params) {
        return new Promise((resolve, reject) => {
            axiosApi
                .get('/weapp/teamup/order/query_order_detail', { params })
                .then((res) => {
                    let data = res.data.data
                    commit('app/APP_SET_MULTI_LANG', data.data.multi_lang, { root: true })

                    resolve(data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    queryOrderShow({ commit }, params) {
        return new Promise((resolve, reject) => {
            axiosApi
                .get('/weapp/teamup/order/query_order_show', { params })
                .then((res) => {
                    let data = res.data.data
                    resolve(data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
}

// mutations
const mutations = {
    // SET_RADIO_STR(state, data) {
    //     state.radioStr = data
    // },
    // SET_OTHER_ORDERS(state, data) {
    //     state.otherOrders = data
    // },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
