<template>
    <div class="pop" v-if="visible">
        <div class="pop_main">
            <div class="pop_main_scroll">
                <div class="pop_close" @click="hide"></div>
                <div class="food_detail_content">
                    <div class="food_name">
                        {{ translate('item', detail_data.fname) }}
                        <span class="header_required" v-if="detail_data.is_must == 1">{{ $t('common.required') }} </span>
                    </div>
                    <div class="food_text">
                        {{ translate('food_desc', detail_data.fdesc) }}
                    </div>
                    <div class="tips">
                        <div class="header_desc" style="display: inline-block; color: #000; background-color: #ddd; border-radius: 8px; padding: 0 5px">
                            {{ $t('menu.totalAdded') }} {{ mix_fcount }}
                        </div>
                        <div v-if="detail_data.once_limit > 0" class="header_desc" style="display: inline-block; color: #000; background-color: #e6a9b4; border-radius: 8px; padding: 0 5px">
                            <span>{{ $t('common.limitPrefix') }} {{ detail_data.once_limit }} {{ $t('common.limitSuffix') }}</span>
                        </div>

                        <div
                            class="header_desc"
                            style="display: inline-block; color: #000; background-color: #e6a9b4; border-radius: 8px; padding: 0 5px"
                            v-if="detail_data.teamup_limit > 0 && detail_data.teamup_limit - detail_data.teamup_sales_count > 0"
                        >
                            <span>{{ $t('common.remainPrefix') }} {{ detail_data.teamup_limit - detail_data.teamup_sales_count }} {{ $t('common.remainSuffix') }}</span>
                        </div>
                    </div>
                    <div class="food_img">
                        <!-- <img src="../assets/images/info_image.png" width="100%" /> -->
                        <img :src="detail_data.fpic" width="100%" />
                        <div class="food_tab tbd" v-if="detail_data.is_tbd">${{ detail_data.teamup_price }}</div>
                        <div class="food_tab" v-else>
                            <!-- <div class="food_price" v-if="detail_data.fprice_original == ''">
                                <span class="fs_16">${{ detail_data.fprice }}</span>
                            </div> -->
                            <div class="food_price">
                                <span class="fs_16" style="text-decoration: line-through">${{ detail_data.fprice }}</span>
                                <span class="fs_16" style="color: #d63239; font-weight: bold"> ${{ detail_data.teamup_price }} </span>
                                <span style="color: #d63239">{{ $t('menu.promoPrice') }}</span>
                            </div>
                            <!-- <div class="pickup_price" v-if="pickup_discount_enable && detail_data.fprice_pickup">
                                <span class="fs_16">${{ detail_data.fprice_pickup }}</span>
                                <span>for pickup</span>
                            </div> -->
                        </div>
                    </div>
                    <div class="food_level" v-if="detail_data.feature_default_value">
                        <div class="level_icon">{{ $t('common.required') }}</div>
                        <h4>{{ translate('feature_name', detail_data.feature_name) }}</h4>
                        <div class="spicy_outer">
                            <div class="spicy_inner">
                                <div class="radio_box" v-for="(option_item, index) in option_list" :key="index">
                                    <div class="radio_item">
                                        <input :id="'spicy_level' + index" type="radio" name="feature_index" :value="index" v-model="feature_name_index" />
                                        <label class="m_label" :for="'spicy_level' + index">{{ translate('feature_value', option_item) }}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="food_side" v-for="(item, index) in addon_list" :key="item.addon_id">
                        <div class="level_icon" v-if="item.addon_is_required == '1'">
                            {{ $t('common.required') }}
                        </div>
                        <div class="level_icon" v-else>
                            {{ $t('common.optional') }}
                        </div>
                        <h4>
                            <!-- Add-on Extra Side -->
                            {{ $t('common.addon') }}-{{ translate('addon_name', item.addon_name) }}
                            <span>{{ item.addon_choose_num_text }}</span>
                            <span class="item_title_extra" v-if="item.total_item_count > 0">
                                {{ '(select ' + item.total_item_count + ' items)' }}
                            </span>
                        </h4>
                        <div class="side_inner">
                            <div class="side_item" v-for="(addon_item, index2) in item.items" v-bind:key="index2">
                                <div class="addon_info">
                                    <div class="radio_box">
                                        <div class="radio_item">
                                            <input
                                                :id="'choose_time_' + index + '_' + index2"
                                                type="checkbox"
                                                name="choose_time"
                                                :disabled="addon_item.availability == 0"
                                                @click="choose_addon(addon_item, $event, item, index2)"
                                                value="0"
                                            />
                                            <label class="m_label" :for="'choose_time_' + index + '_' + index2"
                                                >{{ translate('addon_value', addon_item.name) }}{{ addon_item.availability == 1 ? '' : $t('menu.soldOut') }}</label
                                            >
                                        </div>
                                    </div>
                                    <div class="side_price">${{ addon_item.price }}</div>
                                </div>
                                <PlusMinus
                                    style="margin: 0 0 12px 10px"
                                    :plusCb="() => change_addon_count(addon_item, item, 'add', index2)"
                                    :minusCb="() => change_addon_count(addon_item, item, 'minus', index2)"
                                    :count="addon_count_dict[item.addon_id + '_' + index2]"
                                    v-if="item.multiple_choice == 1 && addon_count_dict[item.addon_id + '_' + index2] > 0"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="food_special">
                        <h4 style="margin: 10px 0">{{ $t('menu.specialInstructions') }}</h4>
                        <div class="special_info">
                            <textarea placeholder="Dressing on the side? No pickles? Let us know here." rows="2" cols="40" v-model="note"></textarea>
                        </div>
                    </div>
                    <div class="food_quantity">
                        <h4 style="margin: 10px 0">{{ $t('menu.quantity') }}</h4>
                        <PlusMinus class="food_count" :plusCb="() => change_food_count('add')" :minusCb="() => change_food_count('minus')" :count="choose_count" />
                    </div>
                    <div class="quantity_btn">
                        <button class="" @click="do_save">
                            <span style="margin-right: 10px">{{ $t('cart.addToCart') }}</span>
                            <span>${{ total_price }}</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.tbd {
    font-size: 16px;
}
.pop .pop_main {
    padding: 16px 0;
    max-height: 70%;
    // overflow-y: scroll;
}
.pop_main_scroll {
    overflow-y: scroll;
    box-sizing: border-box;
    padding: 0 0.625rem;
    max-height: 70vh;
}
.header_required {
    display: inline-block;
    margin-left: 10px;
    padding: 5px;
    height: 15px;
    border-radius: 5px;
    background: #000;
    color: #fff;
    font-size: 14px;
    line-height: 15px;
}
.tips {
    margin-bottom: 20px;
    .header_desc {
        margin-right: 10px;
        margin-bottom: 10px;
        color: #262626;
        word-break: break-all;
        font-size: 14px;
        font-family: UberMoveText-Regular, Helvetica, sans-serif;
        line-height: 20px;

        -webkit-font-smoothing: antialiased;
    }
}
.pop .pop_close {
    right: 1.25rem;
    z-index: 3;
    cursor: pointer;
}
.food_detail_content h4 {
    // margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.3;
}
.food_detail_content h4 span {
    font-size: 13px;
}
.food_name {
    display: flex;
    align-items: center;
    color: #262626;
    font-size: 26px;
    line-height: 34px;
}
.food_text {
    margin: 5px 0 20px;
    text-align: left;
    font-size: 12px;
    line-height: 16px;
}
.food_img {
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    min-height: 32px;
    max-height: 258px;
}
.food_tab {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    box-sizing: border-box;
    padding: 0 10px;
    width: 100%;
    height: 32px;
    background-color: rgba(255, 255, 255, 0.5);
    line-height: 32px;
}
.food_tab span {
    margin-right: 5px;
    color: #6f7070;
    font-size: 12px;
}
.food_tab .fs_16 {
    font-size: 18px;
}
.food_price {
    margin-right: 16px;
}
.old_price {
    margin-right: 20px;
    text-decoration: line-through;
}
.new_price {
    span {
        color: black !important;
    }
}
.pickup_price {
    margin-left: 12px;
    span {
        color: #d63239 !important;
    }
}
.food_level,
.food_side {
    position: relative;
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 10px 7px;
    border-radius: 4px;
    background-color: #f7f7f7;
}
.level_icon {
    position: absolute;
    top: 7px;
    right: 0px;
    padding-right: 3px;
    width: 58px;
    height: 18px;
    background-image: url('../assets/images/required_icon.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    color: #fff;
    text-align: right;
    font-size: 12px;
    line-height: 18px;
}
.special_info {
    margin-top: 8px;
}
.special_info textarea {
    display: block;
    box-sizing: border-box;
    padding: 10px 5px;
    width: 100%;
    height: 112px;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    font-size: 16px;
}
.food_quantity {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    .food_count {
        display: inline-flex;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        box-sizing: border-box;
        width: 79px;
        height: 27px;
        // border: 1px solid #707070;
        // border-radius: 14px;
        line-height: 25px;
        ::v-deep .number_button {
            box-sizing: border-box;
            margin: 0;
            width: 16px;
            border: none;
            background-color: #fff;
            color: #707070;
            text-transform: none;
            font-size: 16px;
            line-height: 16px;
            cursor: pointer;

            -webkit-appearance: button;
        }
    }
}
.quantity_btn {
    display: flex;
    justify-content: flex-end;
}
.quantity_btn button {
    display: flex;
    justify-content: center;
    padding: 8px 16px;
    min-width: 180px;
    // max-width: 50%;
    border: none;
    border-radius: 4px;
    background-color: #d63239;
    color: #fff;
    font-size: 16px;
}
.quantity_num {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    width: 79px;
    height: 27px;
    border: 1px solid #707070;
    border-radius: 14px;
    line-height: 25px;
}
.quantity_num_button {
    box-sizing: border-box;
    margin: 0;
    width: 16px;
    border: none;
    background-color: #fff;
    color: #707070;
    text-transform: none;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;

    -webkit-appearance: button;
}
.order_item_number .order_custom_input {
    display: inline-flex;
}
.quantity_num_input {
    display: flex;
    align-items: center;
}
.quantity_num_input input {
    width: 24px;
    border: 0;
    color: #707070;
    text-align: center;
    font-size: 14px;
}
/* .spicy_outer {
    position: relative;
    height: 40px;
} */
.spicy_inner {
    // display: flex;
    font-size: 12px;
}
.spicy_inner .radio_box {
    // margin-right: 16px;
    margin-bottom: 10px;
}
.spicy_inner .radio_box:last-child {
    margin-bottom: 0px;
}
.side_item {
    .addon_info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 12px;
    }
}

.radio_box {
    display: flex;
    display: -webkit-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.radio_item {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 0;
    height: 44px;
    line-height: 44px;
    input {
        margin-right: 5px;
        min-width: 18px;
        width: 18px;
        height: 18px;
    }
}
.m_radio {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background-color: transparent;
}
.m_radio .big_circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    cursor: pointer;
}
.big_circle b {
    position: absolute;
    top: 40%;
    left: 40%;
    z-index: 1;
    display: block;
    width: 20%;
    height: 20%;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.6);
}
.m_icon {
    position: absolute;
    top: 11px;
    left: 0;
    display: block;
    box-sizing: content-box;
    width: 22px;
    height: 22px;
}
.m_radio_icon {
    border: 1px solid #707070;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: #fff;
}
.m_radio .m_radio_icon {
    top: 11px;
    left: 11px;
    z-index: 2;
}
.m_icon::before,
.m_icon::after {
    position: absolute;
    display: block;
    content: '';
}
.m_radio_icon::after {
    top: 4px;
    left: 4px;
    z-index: 2;
    width: 14px;
    height: 14px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background-color: none;
}
.m_radio input:checked + span.m_icon::after {
    background-color: #d63239;
}

.food_detail_content .radio_item {
    // height: 24px;
    height: auto;
    line-height: 1.3;
    // padding-left: 24px;
}
.food_detail_content .m_radio {
    width: 24px;
    height: 24px;
}
.food_detail_content .m_radio .big_circle {
    width: 24px;
    height: 24px;
}
.food_detail_content .m_radio .m_radio_icon {
    top: 5px;
    left: 5px;
    width: 12px;
    height: 12px;
}
.food_detail_content .m_radio_icon::after {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
}

@media screen and (max-width: 641px) {
    .food_detail_content h4 {
        font-size: 0.875rem;
    }
    .food_name {
        font-size: 26px;
    }
    .quantity_btn button {
        padding: 0.25rem 0.5rem;
        width: 100%;
        font-size: 0.875rem;
    }
    .special_info textarea {
        font-size: 16px;
    }
    .quantity_num {
        width: 4.5rem;
        height: 1.5rem;
        line-height: 1.375rem;
    }
    .quantity_num_button {
        width: 0.875rem;
        font-size: 0.875rem;
    }
    .quantity_num_input input {
        background-color: transparent;
    }
    .side_item .addon_info,
    .spicy_inner,
    .food_text {
        font-size: 16px;
    }
    // .addon_info .radio_item {
    //     height: auto;
    // }
    // .addon_info .radio_item input {
    //     min-width: 18px;
    // }
}
</style>

<script>
import Vue from 'vue'
import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex'
import PlusMinus from '@/components/Plus_Minus'
const FoodStore = createNamespacedHelpers('Food')

export default {
    name: 'foodDetail',
    components: {
        PlusMinus,
    },
    data() {
        return {
            note: '',
            addon_chosen_count: 0,
            addon_total_price_pickup: 0,
            addon_total_price: 0,
            choose_count: 1,
            addon_dict: {},
            visible: false,
            addon_count_dict: {},
            feature_name_index: '',
        }
    },
    watch: {
        choose_count() {
            let _price = this.detail_data.teamup_price
            this.total_price = (this.choose_count * (parseFloat(_price) + parseFloat(this.addon_total_price))).toFixed(2)
        },
        addon_chosen_count() {
            let _price = this.detail_data.teamup_price
            this.total_price = (this.choose_count * (parseFloat(_price) + parseFloat(this.addon_total_price))).toFixed(2)
        },
    },
    created() {},
    mounted() {
        if (this.detail_data.feature_values) {
            this.feature_name_index = this.detail_data.feature_values.split(',').indexOf(this.detail_data.feature_default_value)
            this.feature_name_index = this.feature_name_index > -1 ? this.feature_name_index : 0
        }
    },
    computed: {
        ...mapGetters({
            language: 'app/get_language',
            translate: 'app/translate',
            // is_vip: 'app/get_if_is_vip',
            // pickup_discount_enable: 'app/get_pickup_discount_enable'
        }),
        ...FoodStore.mapState(['detail_data', 'pageOpen', 'mix_fcount']),
        // resetData() {
        //     this.$data = {
        //         ...this.$data,
        //         addon_chosen_count: 0,
        //         addon_total_price_pickup: 0,
        //         addon_total_price: 0,
        //         choose_count: 1,
        //         addon_dict: {},
        //         addon_count_dict: {}
        //     };
        // },
        option_list() {
            return this.detail_data['feature_values'].split(',')
            // return this.transNames({ obj: this.detail_data, key: 'feature_values', split: ',' })
        },
        addon_list() {
            let addon_data = this.detail_data.addon_data
            if (addon_data && addon_data.length > 0) {
                let addon_arr_data = []
                for (let i = 0; i < addon_data.length; i++) {
                    let pickup_discount = addon_data[i].pickup_discount
                    let total_item_count = addon_data[i].total_item_count
                    let addon_names = addon_data[i].addon_values_name.split('|')
                    let addon_names_chn = addon_data[i].addon_values_name_chn.split('|')
                    // let addon_names = this.transNames({ obj: addon_data[i], key: 'addon_values_name', split: '|' })
                    let addon_prices = addon_data[i].addon_values_price.split('|')
                    let addon_availabilitys = addon_data[i].addon_values_availability.split('|')
                    let max_item_count = addon_data[i].max_item_count
                    let min_item_count = addon_data[i].min_item_count
                    let addon_one_item = []
                    for (let i = 0, len = addon_names.length; i < len; i++) {
                        addon_one_item.push({
                            name: addon_names[i],
                            name_chn: addon_names_chn[i] || addon_names[i],
                            price: addon_prices[i] || '0.00',
                            pickup_discount: pickup_discount,
                            availability: addon_availabilitys[i],
                        })
                    }

                    let temp_addon_choose_num = ''
                    let _num = addon_data[i].addon_available_number
                    temp_addon_choose_num = `(${this.$t('common.chooseOne')})`

                    if (_num == 'all') {
                        _num = addon_names.length
                    }

                    if (parseInt(_num) > 1) {
                        temp_addon_choose_num = `(${this.$t('common.chooseMutiPrefix')} ${_num} ${this.$t('common.chooseMutiSuffix')})`
                    }

                    if (max_item_count > 0 && min_item_count > 0) {
                        temp_addon_choose_num = `(${this.$t('common.addonMinMaxPrefix')} ${min_item_count}-${max_item_count} ${this.$t('common.addonMinMaxSuffix')})`
                    } else if (max_item_count > 0 && min_item_count == 0) {
                        temp_addon_choose_num = `(${this.$t('common.addonMaxPrefix')} ${max_item_count} ${this.$t('common.addonMaxSuffix')})`
                    } else if (max_item_count == 0 && min_item_count > 0) {
                        temp_addon_choose_num = `(${this.$t('common.addonMinPrefix')} ${min_item_count} ${this.$t('common.addonMinSuffix')})`
                    }
                    addon_arr_data.push({
                        addon_available_number: addon_data[i].addon_available_number,
                        addon_choose_num_text: temp_addon_choose_num,
                        addon_id: addon_data[i].addon_id,
                        addon_is_required: addon_data[i].addon_is_required,
                        // addon_name: this.trans(addon_data[i], 'addon_name'),
                        addon_name: addon_data[i].addon_name,
                        addon_name_chn: addon_data[i].addon_name_chn || addon_data[i].addon_name,
                        items: addon_one_item,
                        pickup_discount: pickup_discount,
                        multiple_choice: addon_data[i].multiple_choice,
                        total_item_count: total_item_count,
                        max_item_count: addon_data[i].max_item_count,
                        min_item_count: addon_data[i].min_item_count,
                    })
                }

                return addon_arr_data
            }

            return []
        },
        total_price: {
            get() {
                let _price = this.detail_data.teamup_price
                return (this.choose_count * (parseFloat(_price) + parseFloat(this.addon_total_price))).toFixed(2)
            },
            set() {},
        },
    },
    methods: {
        ...mapActions({
            edit_food: 'app/edit_cart_food',
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG',
        }),
        do_save() {
            let _food_data = JSON.parse(JSON.stringify(this.detail_data))
            let addon_names = []
            let addon_names_chn = []
            let addon_prices = []
            let addon_counts = []
            let addon_pickup_discounts = []

            if (_food_data.once_limit > 0 && this.mix_fcount + this.choose_count * 1 > _food_data.once_limit) {
                this.set_msg({
                    msg: this.$t('common.exceedOrder'),
                })
                return
            }

            if (_food_data.teamup_limit > 0 && this.mix_fcount + this.choose_count * 1 > _food_data.teamup_limit - _food_data.teamup_sales_count) {
                this.set_msg({
                    msg: this.$t('common.exceedStock'),
                })
                return
            }

            for (let o in this.addon_dict) {
                if (this.addon_dict[o] && this.addon_dict[o].length > 0) {
                    let items = this.addon_dict[o]
                    let one_addon_names = []
                    let one_addon_names_chn = []
                    let one_addon_prices = []
                    let one_addon_counts = []
                    let one_addon_discounts = []

                    for (let i = 0; i < items.length; i++) {
                        one_addon_names.push(items[i].item_name)
                        one_addon_names_chn.push(items[i].item_name_chn)
                        one_addon_prices.push(items[i].item_price)
                        one_addon_counts.push(this.addon_count_dict[o + '_' + items[i].item_index])
                        one_addon_discounts.push(items[i].pickup_discount)
                    }

                    addon_names.push(one_addon_names.join('|'))
                    addon_names_chn.push(one_addon_names_chn.join('|'))
                    addon_prices.push(one_addon_prices.join('|'))
                    addon_counts.push(one_addon_counts.join('|'))
                    addon_pickup_discounts.push(one_addon_discounts.join('|'))
                }
            }

            let false_flag = false
            for (let i = 0; i < this.addon_list.length; i++) {
                if (this.addon_list[i].addon_is_required == 1) {
                    if (!this.addon_dict[this.addon_list[i].addon_id] || (this.addon_dict[this.addon_list[i].addon_id] && this.addon_dict[this.addon_list[i].addon_id].length == 0)) {
                        this.set_msg({
                            msg: 'Please choose addon',
                        })
                        false_flag = true
                        break
                    }
                }

                if (this.addon_list[i].total_item_count > 0) {
                    let choose_addons = this.addon_dict[this.addon_list[i].addon_id] || []
                    let count = 0

                    choose_addons.map((item) => {
                        count = count + this.addon_count_dict[this.addon_list[i].addon_id + '_' + item.item_index]
                    })

                    if (count != this.addon_list[i].total_item_count) {
                        this.set_msg({
                            msg: '[' + this.addon_list[i].addon_name + ']Please choose ' + this.addon_list[i].total_item_count + ' addons',
                        })
                        false_flag = true
                        break
                    }
                }
                if (this.addon_list[i].max_item_count > 0 || this.addon_list[i].min_item_count > 0) {
                    let choose_addons = this.addon_dict[this.addon_list[i].addon_id] || []
                    let count = 0

                    choose_addons.map((item) => {
                        count = count + this.addon_count_dict[this.addon_list[i].addon_id + '_' + item.item_index]
                    })

                    let addon_name = this.translate('addon_name', this.addon_list[i].addon_name)
                    if (this.addon_list[i].min_item_count > 0 && count < this.addon_list[i].min_item_count) {
                        let num = this.addon_list[i].min_item_count
                        let msg_text = this.$t('common.addonWarnMin')
                        msg_text = msg_text.replace(/\$\{addon_name\}/g, addon_name)
                        msg_text = msg_text.replace(/\$\{num\}/g, num)
                        this.set_msg({
                            msg: msg_text,
                        })
                        return
                    }
                    if (this.addon_list[i].max_item_count > 0 && count > this.addon_list[i].max_item_count) {
                        let num = this.addon_list[i].max_item_count
                        let msg_text = this.$t('common.addonWarnMax')
                        msg_text = msg_text.replace(/\$\{addon_name\}/g, addon_name)
                        msg_text = msg_text.replace(/\$\{num\}/g, num)
                        this.set_msg({
                            msg: msg_text,
                        })
                        return
                    }
                }
            }

            if (false_flag) {
                return
            }

            _food_data.fcount = this.choose_count * 1
            // _food_data.fprice_pickup = this.pickup_discount_enable && _food_data.fprice_pickup ? _food_data.fprice_pickup : ''

            let save_data = {
                cartid: this.$store.state.app.shared_cart_id,
                tid: this.$store.state.app.shared_tid,
                uid: this.$store.state.app.email,
                uname: this.$store.state.app.uname,
                router: this.$router,
                type: 'add',
                // is_vip: this.is_vip,
                this: this,
                fname: this.translate('item', _food_data.fname),
                food_data: _food_data,
                extra_data: {
                    feature_name: this.detail_data.feature_values ? this.detail_data.feature_values.split(',')[this.feature_name_index] : '',
                    feature_name_chn: this.detail_data.feature_values ? this.detail_data.feature_values_chn.split(',')[this.feature_name_index] : '',
                    note: this.note,
                    addon_names: addon_names.join('@@'),
                    addon_names_chn: addon_names_chn.join('@@'),
                    addon_prices: addon_prices.join('@@'),
                    addon_counts: addon_counts.join('@@'),
                    addon_pickup_discounts: addon_pickup_discounts.join('@@'),
                    addon_total_price: this.addon_total_price,
                    addon_total_price_pickup: this.addon_total_price_pickup,
                    add_count: this.choose_count * 1,
                    once_limit: _food_data.once_limit,
                    teamup_limit: _food_data.teamup_limit,
                },
            }

            setTimeout(async () => {
                await this.edit_food(save_data)
                this.visible = false
            }, 500)
        },
        change_food_count(type) {
            if (type == 'add') {
                let _food_data = JSON.parse(JSON.stringify(this.detail_data))
                if (_food_data.once_limit > 0 && this.mix_fcount + parseInt(this.choose_count) + 1 > _food_data.once_limit) {
                    this.set_msg({
                        msg: this.$t('common.exceedOrder'),
                    })
                    return
                }
                if (_food_data.teamup_limit > 0 && this.mix_fcount + parseInt(this.choose_count) + 1 > _food_data.teamup_limit - _food_data.teamup_sales_count) {
                    this.set_msg({
                        msg: this.$t('common.exceedStock'),
                    })
                    return
                }
                this.choose_count = this.choose_count * 1 + 1
            } else if (type == 'minus' && this.choose_count > 1) {
                this.choose_count = this.choose_count * 1 - 1
            }
        },
        show() {
            this.visible = true
            // this.resetData();
        },
        hide() {
            this.visible = false
        },
        change_addon_count(addon_item, item, type, index) {
            let now_value = this.addon_count_dict[item.addon_id + '_' + index]

            if (type === 'minus' && now_value > 1) {
                this.$set(this.addon_count_dict, item.addon_id + '_' + index, now_value - 1)
                this.addon_total_price = (parseFloat(this.addon_total_price) - parseFloat(addon_item.price)).toFixed(2)
                this.addon_total_price_pickup = (parseFloat(this.addon_total_price_pickup) - parseFloat(addon_item.price * item.pickup_discount)).toFixed(2)
            }

            if (type === 'add') {
                this.$set(this.addon_count_dict, item.addon_id + '_' + index, now_value + 1)
                this.addon_total_price = (parseFloat(this.addon_total_price) + parseFloat(addon_item.price)).toFixed(2)
                this.addon_total_price_pickup = (parseFloat(this.addon_total_price_pickup) + parseFloat(addon_item.price * item.pickup_discount)).toFixed(2)
            }
        },
        choose_addon(addon_item, e, addon_all_data, index) {
            if (
                e.target.checked &&
                addon_all_data.addon_available_number != 'all' &&
                this.addon_dict[addon_all_data.addon_id] &&
                this.addon_dict[addon_all_data.addon_id].length >= parseInt(addon_all_data.addon_available_number)
            ) {
                e.preventDefault()
                return
            }

            if (e.target.checked) {
                if (!this.addon_dict[addon_all_data.addon_id]) {
                    this.addon_dict[addon_all_data.addon_id] = []
                    this.addon_count_dict[addon_all_data.addon_id + '_dict'] = {}
                }
                this.$set(this.addon_count_dict, addon_all_data.addon_id + '_' + index, 1)

                this.addon_dict[addon_all_data.addon_id].push({
                    item_name: addon_item.name,
                    item_name_chn: addon_item.name_chn,
                    // item_name: this.trans(addon_item, 'name'),
                    item_index: index,
                    item_price: addon_item.price,
                    pickup_discount: addon_all_data.pickup_discount,
                })
                this.addon_total_price = (parseFloat(this.addon_total_price) + parseFloat(addon_item.price)).toFixed(2)
                this.addon_total_price_pickup = (parseFloat(this.addon_total_price_pickup) + parseFloat(addon_item.price * addon_all_data.pickup_discount)).toFixed(2)
                this.addon_chosen_count = this.addon_chosen_count + 1
            } else {
                let now_count = this.addon_count_dict[addon_all_data.addon_id + '_' + index]
                let addon_item_list = JSON.parse(JSON.stringify(this.addon_dict[addon_all_data.addon_id]))
                for (let i = 0; i < addon_item_list.length; i++) {
                    if (addon_item_list[i].item_name === addon_item.name) {
                        addon_item_list.splice(i, 1)
                        this.$set(this.addon_count_dict, addon_all_data.addon_id + '_' + index, 0)
                        delete this.addon_count_dict[addon_all_data.addon_id + '_' + index]
                    }
                }
                this.addon_dict[addon_all_data.addon_id] = addon_item_list
                this.addon_total_price = (parseFloat(this.addon_total_price) - now_count * parseFloat(addon_item.price)).toFixed(2)
                this.addon_total_price_pickup = (parseFloat(this.addon_total_price_pickup) - now_count * parseFloat(addon_item.price * addon_all_data.pickup_discount)).toFixed(2)
                this.addon_chosen_count = this.addon_chosen_count - 1
            }
        },
    },
}
</script>
