import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const DEFAULT_LANG = 'eng'
// const LOCALE_KEY = 'localeLanguage';

const locales = {
    chn: require('./chn.json'),
    eng: require('./eng.json'),
    kor: require('./kor.json'),
}

const i18n = new VueI18n({
    locale: DEFAULT_LANG,
    messages: locales,
})

export const setLang = (lang) => {
    // if (lang === undefined) {
    //     lang = window.localStorage.getItem(LOCALE_KEY);
    //     if (locales[lang] === undefined) {
    //         lang = DEFAULT_LANG;
    //     }
    // }
    // window.localStorage.setItem(LOCALE_KEY, lang);

    Object.keys(locales).forEach((lang) => {
        document.body.classList.remove(`lang-${lang}`)
    })
    document.body.classList.add(`lang-${lang}`)
    document.body.setAttribute('lang', lang)

    Vue.config.lang = lang || DEFAULT_LANG
    i18n.locale = lang
}

export const trans = (obj, key) => {
    const locale = i18n.locale
    switch (locale) {
        case 'chn':
            return obj[key + '_chn'] || obj[key] || obj[key + '_eng']
        case 'kor':
            return obj[key + '_kor'] || obj[key] || obj[key + '_eng']

        default:
            return obj[key] || obj[key + '_eng']
    }
}

export const transNames = ({ obj, key, split }) => {
    const locale = i18n.locale
    const values = obj[key].split(split)
    let valuesLocale = []
    switch (locale) {
        case 'chn':
            valuesLocale = obj[key + '_chn'].split(split)
            break
        case 'kor':
            valuesLocale = obj[key + '_kor'].split(split)
            break
    }
    const result = []
    values.forEach((item, index) => {
        const val = valuesLocale[index]
        if (val) {
            result.push(val)
        } else {
            result.push(values[index])
        }
    })
    return result
}

// setLang();
export default i18n
