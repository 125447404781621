<template>
    <div class="pop" v-if="visible">
        <div class="pop_main">
            <div class="pop_close" @click="hide"></div>
            <div class="address_main">
                <div class="pop_title">{{ $t('checkout.addAddress') }}</div>
                <div class="pop_input_box">
                    <input type="text" :placeholder="$t('checkout.street')" v-model="street_address1" />
                </div>
                <div class="pop_input_box">
                    <input type="text" :placeholder="$t('checkout.street2')" v-model="street_address2" />
                </div>
                <div class="pop_input_box">
                    <input type="text" :placeholder="$t('checkout.city')" v-model="city" />
                </div>
                <div class="pop_input_box">
                    <input type="text" :placeholder="$t('checkout.state')" v-model="state" />
                </div>
                <div class="pop_input_box">
                    <input type="text" :placeholder="$t('checkout.postCode')" v-model="zip_code" />
                </div>
                <div class="pop_btn_line">
                    <button @click="saveAddress">{{ saveBtnText }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters, createNamespacedHelpers } from 'vuex'
const App = createNamespacedHelpers('app')
export default {
    name: 'AddressForm',
    components: {},
    data() {
        return {
            visible: false,
            street_address1: '',
            street_address2: '',
            zip_code: '',
            zip_code_index: '',
            city: '',
            state: '',
        }
    },
    computed: {
        ...App.mapState({
            street1: 'street_address1',
            street2: 'street_address2',
            zip: 'zip_code',
            zipIndex: 'zip_code_index',
            _city: 'city',
            _state: 'state',
        }),
        ...App.mapGetters({
            delivery_data: 'get_delivery_data',
            language: 'get_language',
            teamup: 'get_teamup',
        }),
        saveBtnText() {
            return this.$t('common.save')
        },
    },
    mounted() {
        this.street_address1 = this.street1
        this.street_address2 = this.street2
        this.zip_code = this.zip
        this.zip_code_index = this.zipIndex
        this.city = this._city
        this.state = this._state
    },
    methods: {
        ...App.mapMutations(['APP_SET_ADDRESS', 'APP_SET_MSG']),
        show() {
            this.visible = true
        },
        hide() {
            this.visible = false
        },
        set_address(address) {
            this.APP_SET_ADDRESS(address)
        },
        toggleAddressLayer() {
            this.$emit('toggleAddressLayer')
        },
        getAppStore(name) {
            return this.$store.state.app[name]
        },
        set_msg(msg) {
            this.APP_SET_MSG(msg)
        },
        saveAddress() {
            if (this.street_address1 === '') {
                this.set_msg({ msg: this.language === 'chn' ? '请输入地址' : 'Address is required' })
                return
            }

            if (this.zip_code === '') {
                this.set_msg({ msg: this.language === 'chn' ? '请输入邮政编码' : 'Zip code is required' })
                return
            }

            // check zip code
            let zip_error_flag = true

            for (let i = 0; i < this.teamup.delivery_data.length; i++) {
                const item = this.teamup.delivery_data[i]
                if (item.zip.indexOf(this.zip_code) > -1) {
                    zip_error_flag = false
                    this.zip_code_index = i
                    break
                }
            }
            if (zip_error_flag) {
                this.set_msg({ msg: this.language === 'chn' ? '不在配送区' : 'Not a service area' })
                return
            }

            this.set_address({
                state: this.state,
                city: this.city,
                street_address1: this.street_address1,
                street_address2: this.street_address2,
                zip_code: this.zip_code,
                zip_code_index: this.zip_code_index,
            })
            this.has_saved_address = true
            this.hide()
        },
    },
}
</script>

<style lang="scss" scoped>
.show_address {
    transition-duration: 235ms !important;
    transform: translateY(0) !important;
}
.address-layer {
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    overflow-y: auto;
    padding: 0px 0 40px;
    width: 280px;
    width: 100%;
    height: 100%;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    background: #fff;
    font-size: 14px;
    opacity: 0.97;
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
    transform: translateY(-100%);
}
.pop_input_box input {
    color: #666;
}
.pop_input_box input::-webkit-input-placeholder {
    color: #ccc;
}
</style>
