<template>
    <div class="address">
        <div :class="['item', pickup_address_active == index ? 'active' : '']" v-for="(item, index) in teamup.pickup_data" :key="index" @click="chooseAddress(index)">
            <div class="name">Name: {{ item.name }}</div>
            <div class="detail">Address: {{ item.address }}</div>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex'
export default {
    name: 'PickupAddress',

    props: {
        // address: {
        //     type: Array,
        //     default: [],
        // },
    },
    data() {
        return {
            count: this.num,
        }
    },
    computed: {
        ...mapGetters({
            teamup: 'app/get_teamup',
            pickup_address_active: 'app/get_pickup_address_active',
        }),
    },
    mounted() {},
    methods: {
        ...mapMutations({
            set_pickup_address_active: 'app/APP_SET_PICKUP_ADDRESS_ACTIVE',
        }),
        chooseAddress(index) {
            this.set_pickup_address_active(index)
        },
    },
}
</script>

<style lang="scss" scoped>
.address {
    font-size: 14px;
    padding: 12px;
    overflow-y: scroll;
    max-height: calc(80vh - 100px);
    .item {
        padding: 10px;
        border-radius: 6px;
        .name {
            font-size: 16px;
            font-weight: 600;
        }
        &.active {
            background: rgba(115, 184, 61, 0.16);
        }
    }
}
</style>
