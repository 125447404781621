<template lang="html">
    <div>
        <div id="app">
            <router-view></router-view>
            <div class="loading" v-if="loading">
                <van-loading color="#3db84f" size="60px" />
                <div class="loading-text">Loading</div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped>
.loading {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    .loading-text {
        margin-top: 20px;
        color: #3db84f;
        font-size: 16px;
    }
}
</style>

<script>
import Vue from 'vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import '@/assets/style/normalize.css'
import '@/assets/style/main.css'
import { Toast } from 'vant'
import { setLang } from '@/locales'
import { Loading } from 'vant'

let HAS_TOAST = false

export default {
    name: 'app',
    data() {
        return {}
    },
    components: {
        vanLoading: Loading,
    },
    beforeUpdate() {},
    updated() {},
    beforeCreate() {},
    created() {
        const lang = this.language || 'eng'
        setLang(lang)
    },
    mounted() {},
    watch: {
        msg() {
            if (this.msg) {
                if (HAS_TOAST) {
                    Toast.clear()
                }
                HAS_TOAST = true
                let temp_toast_instance = Toast(this.msg)
                setTimeout(() => {
                    temp_toast_instance.clear()
                    this.set_msg('')
                    HAS_TOAST = false
                }, 2000)
            }
        },
        store_info() {
            if (this.wechat) {
                let link = `https://${window.location.host}/dist/#/menu/${this.teamup.eid}`
                let title = `${this.teamup.event_name}-${this.store_info.sname}`
                this.wechat.share(title, this.teamup.event_description, link, this.store_info.slogo)
            }
        },
    },
    computed: {
        ...mapGetters({
            msg: 'app/get_app_msg',
            language: 'app/get_language',
            loading: 'app/get_loading',
            store_info: 'app/get_store_info',
            teamup: 'app/get_teamup',
        }),
    },
    methods: {
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG',
            set_language: 'app/APP_SET_LANGUAGE',
        }),
    },
}
</script>
