<template>
    <div class="container">
        <div class="header">
            <div class="cart-icon">
                <span class="iconfont icon-cart" @click="previous"></span>
            </div>
            <div class="title">{{ $t('checkout.checkout') }}</div>
            <div class="card-icon">
                <!-- <span class="iconfont icon-card-o"></span> -->
            </div>
        </div>
        <!-- <notice-bar class="broadcast" text="在代码阅读过程中人们说脏话的频率是衡量代码质量的唯一标准。" /> -->
        <div class="column gery">
            <div class="title">{{ $t('checkout.orderInfo') }}</div>
            <div class="info">
                <div class="label" v-if="teamup.delivery_method == 'pickup'">{{ $t('common.timeToGetPickup') }}</div>
                <div class="label" v-else>{{ $t('common.timeToGet') }}</div>
                <div class="content">
                    <span class="text">{{ $moment(Number(teamup.teamup_time)).tz(timezone).format('ddd, MMM DD, YYYY H:mm A') }} - {{ TimezoneCode[timezone] }}</span>
                </div>
            </div>
            <div class="info" v-if="teamup.delivery_method == 'pickup'">
                <div class="label">{{ $t('common.pickupAddress') }}</div>
                <div class="content" @click="showPickupAddress = true">
                    <div class="text">{{ teamup.pickup_data[pickup_address_active].name }}</div>
                    <div class="text small">{{ teamup.pickup_data[pickup_address_active].address }}</div>
                    <div style="margin-top: 10px"><span class="check">Choose</span></div>
                </div>
            </div>
            <div class="info" v-else>
                <div class="label">{{ $t('common.doorstepDelivery') }}</div>
                <div class="content" @click="showAddressForm">
                    <span class="text address" v-if="zip_code">{{ street_address1 }} {{ street_address2 }}, {{ city }}, {{ state }} {{ zip_code }}</span>
                    <div class="add-address" v-else>+ {{ $t('checkout.yourAddress') }}</div>
                </div>
            </div>
        </div>
        <div class="column">
            <div class="title">{{ $t('checkout.enterYourInfo') }}</div>
            <div class="info">
                <div class="label">{{ $t('checkout.name') }}*</div>
                <div class="content">
                    <input type="text" class="border-input" :placeholder="$t('checkout.yourName')" v-model="name" />
                </div>
            </div>
            <div class="info">
                <div class="label">{{ $t('checkout.phone') }}*</div>
                <div class="content phone">
                    <van-popover v-model="showPopoverAreaCode" trigger="click" :actions="areaCodeList" @select="onSelectAreaCode">
                        <template #reference>
                            <div class="prefix">{{ areaCode }}</div>
                        </template>
                    </van-popover>
                    <input type="number" class="border-input" :placeholder="$t('checkout.yourPhone')" v-model="phone" />
                </div>
            </div>
            <div class="info">
                <div class="label">{{ $t('checkout.special') }}</div>
                <div class="content">
                    <textarea class="border-textarea" rows="3" cols="20" :placeholder="$t('checkout.yourNote')" v-model="special"></textarea>
                </div>
            </div>
        </div>
        <div class="column gery">
            <div class="title">{{ $t('checkout.payment') }}</div>
            <div class="info">
                <div class="label">{{ $t('checkout.selectTip') }}</div>
                <div class="content">
                    <div class="tip">
                        <div :class="['tip-item', item.tIndex == activeTip ? 'active' : '']" @click="onChangeTip(item)" v-for="(item, index) in tipList" :key="index">{{ item.text }}</div>
                        <div :class="['tip-item', activeTip == -1 ? 'active' : '']" @click="onChangeTip({ text: 'other', value: -1, tIndex: -1 })">
                            {{ activeTip == -1 && tipValue != -1 ? (tipValue * 100).toFixed(0) + '%' : 'other' }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="info" style="align-items: stretch">
                <div class="label" style="margin-top: 14px">{{ $t('checkout.paymentOption') }}</div>
                <div class="content">
                    <div class="pay" v-if="teamup.pay_method">
                        <div :class="['item', pay_method == 'stripe' ? 'active' : '']" @click="onChangePayMethod('stripe')" v-if="teamup.pay_method.indexOf('stripe') > -1">
                            <span class="iconfont icon-card"></span>Credit Card
                        </div>
                        <div :class="['item', pay_method == 'offline' ? 'active' : '']" @click="onChangePayMethod('offline')" v-if="teamup.pay_method.indexOf('offline') > -1">
                            <span class="iconfont icon-dollar"></span>
                            <template v-if="teamup.delivery_method == 'pickup'">
                                {{ $t('checkout.offlinePickup') }}
                            </template>
                            <template v-else>
                                {{ $t('checkout.offlineDelivery') }}
                            </template>
                        </div>
                        <div :class="['item', pay_method == 'paypal' ? 'active' : '']" @click="onChangePayMethod('paypal')" v-if="teamup.pay_method.indexOf('paypal') > -1">
                            <span class="iconfont icon-dollar"></span>Paypal
                        </div>
                    </div>
                </div>
            </div>
            <div class="pay_info" style="">
                <Stripe @setStripe="setStripe" v-show="pay_method === 'stripe'" />
                <div v-if="pay_method === 'stripe' && stripe_card_errors">
                    <span>
                        {{ stripe_card_errors }}
                    </span>
                </div>
                <div v-show="is_wechat === 0 && showPaypal" id="paypal-button-container"></div>
                <div v-if="is_wechat === '1' && pay_method === 'paypal'" style="font-size: 13px; color: red">Paypal is not support in Wechat. Please reopen this page in browser!</div>
            </div>
        </div>
        <div class="column">
            <div class="info">
                <div class="label">{{ $t('checkout.orderDetails') }}</div>
            </div>
            <div class="order">
                <div class="item" v-for="(item, index) in cart_food_list" :key="index">
                    <div class="food">
                        <div class="num">{{ item.fcount }} X</div>
                        <div class="name">{{ translate('item', item.fname) }}</div>
                        <div class="price">${{ (item.teamup_price * item.fcount).toFixed(2) }}</div>
                    </div>
                    <span class="feature" v-if="item.feature_name">({{ translate('feature_value', item.feature_name) }})</span>
                    <!-- <template v-for="(addon_name_item, addon_index) in item[language === 'eng' ? 'addon_names' : 'addon_names_chn'].split('@@')" v-if="item.addon_names"> -->
                    <template v-if="item.addon_names">
                        <template v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')">
                            <div class="addon" v-for="(item_name, item_index) in addon_name_item.split('|')" v-bind:key="item_name + '_item_val'">
                                <span v-if="addon_name_item">{{ item.fcount * item.addon_counts.split('@@')[addon_index].split('|')[item_index] }} X {{ translate('addon_value', item_name) }}</span>
                                (${{
                                    (item.fcount * item.addon_counts.split('@@')[addon_index].split('|')[item_index] * item.addon_prices.split('@@')[addon_index].split('|')[item_index]).toFixed(2)
                                }})
                            </div>
                        </template>
                    </template>
                    <div class="remark" v-if="item.note">{{ $t('review.note') }}: {{ item.note }}</div>
                </div>
                <div class="border"></div>
            </div>
        </div>
        <div class="total">
            <div class="row">
                <div class="label">{{ $t('common.subtotal') }}</div>
                <div class="content savin">{{ $t('common.savin') }} $ {{ cart_price_savin }}</div>
                <div class="content no-right">$ {{ cart_price.toFixed(2) }}</div>
            </div>
            <div class="row">
                <div class="label">{{ $t('common.tax') }}</div>
                <div class="content">$ {{ taxAndFee }}</div>
            </div>
            <div class="row" v-if="teamup.delivery_method == 'delivery'">
                <div class="label">{{ $t('common.deliveryFee') }}</div>
                <div class="content">$ {{ delivery_fee }}</div>
            </div>
            <div class="row">
                <div class="label">{{ $t('cart.tip') }}</div>
                <div class="content">$ {{ tip_price }}</div>
            </div>
            <div class="row" style="font-weight: 700">
                <div class="label">{{ $t('common.estimatedTotal') }}</div>
                <div class="content">$ {{ order_price_total }}</div>
            </div>
        </div>
        <div class="button-submit" @click="submitOrder" v-if="showSubmit">{{ $t('checkout.submitOrder') }}</div>
        <AddressForm ref="addressForm" />
        <van-popup v-model="showPopoverTipOther" title="Other Tip" round position="bottom">
            <van-picker show-toolbar :columns="tipOtherList" @cancel="showPopoverTipOther = false" @confirm="onSelectTipOther" />
        </van-popup>
        <!-- pickup address -->
        <van-dialog v-model="showPickupAddress" title="Pickup address" confirm-button-text="confirm">
            <PickupAddress></PickupAddress>
        </van-dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters, createNamespacedHelpers } from 'vuex'
import { Popover, Popup, Picker, Dialog } from 'vant'
import AddressForm from '@/components/AddressForm'
const App = createNamespacedHelpers('app')
import Stripe from '@/components/Stripe'
import { TimezoneCode } from '@/utils/constants'
import PickupAddress from '@/components/PickupAddress'

export default {
    name: 'checkout',
    data() {
        return {
            TimezoneCode,
            showPopoverAreaCode: false,
            areaCodeList: [{ text: '+1' }, { text: '+86' }],
            showPopoverTipOther: false,
            showPickupAddress: false,
            tipOtherList: [],
            tipList: [
                {
                    text: '0%',
                    value: 0,
                    tIndex: 0,
                },
                {
                    text: '5%',
                    value: 0.05,
                    tIndex: 1,
                },
                {
                    text: '8%',
                    value: 0.08,
                    tIndex: 2,
                },
                {
                    text: '10%',
                    value: 0.1,
                    tIndex: 3,
                },
            ],
            activeTip: 0,
            tipValue: 0,
            areaCode: '+1',
            name: '',
            phone: '',
            special: '',
            pay_method: '',
            is_wechat: 0,
            stripe_card_errors: '',
        }
    },
    components: {
        AddressForm,
        vanPopover: Popover,
        vanPopup: Popup,
        vanPicker: Picker,
        Stripe,
        PickupAddress,
        vanDialog: Dialog.Component,
    },
    created() {},
    async mounted() {
        this.set_loading(true)
        let { eid } = this.$route.params
        if (!eid) {
            this.$router.push({ path: '/welcome' })
            return
        }
        this.set_eid({ eid: eid })
        await this.initData({ params: { eid: eid }, router: this.$router })
        this.pay_method = this.teamup.pay_method.split(',')[0]

        // 生成other tip列表
        let arr = []
        for (let i = 1; i < 101; i++) {
            arr.push({
                text: `${i}%`,
                value: i / 100,
            })
        }
        this.tipOtherList = arr
        // 判断是否微信浏览器
        const ua = navigator.userAgent.toLowerCase()
        if (ua.match(/MicroMessenger/i) === 'micromessenger') {
            this.is_wechat = 1
        }
        setTimeout(this.loadPaypal, 2000)
    },
    computed: {
        ...App.mapState(['state', 'city', 'street_address1', 'street_address2', 'zip_code', 'zip_code_index']),
        ...mapGetters({
            cart_food_list: 'app/get_cart_food_list',
            teamup: 'app/get_teamup',
            cart_price: 'app/get_cart_price',
            tax: 'app/get_tax',
            cart_price_savin: 'app/get_cart_price_savin',
            total_food_count: 'app/get_food_count',
            language: 'app/get_language',
            store_info: 'app/get_store_info',
            timezone: 'app/get_timezone',
            pickup_address_active: 'app/get_pickup_address_active',
            translate: 'app/translate',
        }),
        showSubmit() {
            // 是否可以下单
            let now = new Date().getTime()
            if (this.teamup.event_status == 200 && now < this.teamup.end_time && this.pay_method != 'paypal') {
                return true
            }
            return false
        },
        showPaypal() {
            // 是否可以下单-paypal
            let now = new Date().getTime()
            if (this.teamup.event_status == 200 && now < this.teamup.end_time && this.pay_method == 'paypal') {
                return true
            }
            return false
        },
        taxAndFee() {
            return this.cart_price_tax
        },
        cart_price_tax() {
            let _tax = (this.cart_price * this.tax).toFixed(2) * 1
            return _tax < 0 ? 0 : _tax
        },
        cart_price_total() {
            let _price = (1 * (this.cart_price + this.cart_price_tax + Number(this.delivery_fee))).toFixed(2)
            return _price < 0 ? 0 : _price
        },
        tip_price() {
            let _price = (1 * (this.cart_price + this.cart_price_tax).toFixed(2) * this.tipValue).toFixed(2)
            return _price < 0 ? 0 : _price
        },
        order_price_total() {
            let _price = (1 * this.cart_price_total + 1 * this.tip_price).toFixed(2)
            return _price < 0 ? 0 : _price
        },
        delivery_fee() {
            if (this.teamup.delivery_method == 'pickup') {
                return 0
            } else {
                if (this.zip_code_index == -1) {
                    return 0
                }
                let delivery_setting = this.teamup.delivery_data[this.zip_code_index]
                if (delivery_setting.free_delivery_amount == 0 || delivery_setting.free_delivery_amount > this.cart_price) {
                    if (delivery_setting.delivery_fee_type == 'fixed') {
                        return Number(delivery_setting.delivery_fee).toFixed(2)
                    } else {
                        return Number(delivery_setting.delivery_fee * this.cart_price).toFixed(2)
                    }
                } else {
                    return 0
                }
            }
        },
    },
    methods: {
        ...mapActions({
            initData: 'app/initData',
            createOrder: 'order/createOrder',
        }),

        ...App.mapMutations({
            set_loading: 'SET_LOADING',
            set_msg: 'APP_SET_MSG',
            claerCartData: 'APP_CLEAR_CART_DATA',
            setOrderId: 'APP_SET_SHARED_OID',
            set_eid: 'SET_EID',
        }),
        onChangePayMethod(method) {
            this.pay_method = method
        },
        showAddressForm() {
            this.$refs.addressForm.show()
        },
        onSelectAreaCode(action) {
            this.areaCode = action.text
        },
        onChangeTip(tip) {
            this.activeTip = tip.tIndex
            this.tipValue = tip.value
            if (tip.tIndex == -1) {
                this.showPopoverTipOther = true
            }
        },
        onSelectTipOther(tipOther) {
            this.tipValue = tipOther.value
            this.showPopoverTipOther = false
        },
        verifyData() {
            if (!this.zip_code && this.teamup.delivery_method == 'delivery') {
                this.set_msg({ msg: this.$t('checkout.inputZip') })
                return false
            }
            if (!this.name) {
                this.set_msg({ msg: this.$t('checkout.inputName') })
                return false
            }
            if (!this.phone) {
                this.set_msg({ msg: this.$t('checkout.inputPhone') })
                return false
            }
            if (this.tipValue == -1) {
                this.set_msg({ msg: this.$t('checkout.inputTip') })
                return false
            }
            if (this.teamup.delivery_method == 'delivery') {
                // 起送金额 delivery
                let delivery_setting = this.teamup.delivery_data[this.zip_code_index]
                if (delivery_setting.minimum_amount != 0 && delivery_setting.minimum_amount > this.cart_price) {
                    this.set_msg({ msg: this.$t('checkout.minimumAmount') + ' $' + delivery_setting.minimum_amount })
                    return false
                }
            } else if (this.teamup.delivery_method == 'pickup') {
                // 起送金额 pickup
                if (this.teamup.pickup_minimum_amount != 0 && this.teamup.pickup_minimum_amount > this.cart_price) {
                    this.set_msg({ msg: this.$t('checkout.minimumAmount') + ' $' + this.teamup.pickup_minimum_amount })
                    return false
                }
            }
            return true
        },
        submitOrder() {
            if (this.cart_food_list.length < 1) {
                this.set_msg({ msg: this.$t('common.noFood') })
                return
            }
            if (!this.verifyData()) {
                return
            }
            this.set_loading(true)
            let params = {
                eid: this.teamup.eid,
                name: this.name,
                phone: `${this.areaCode} ${this.phone}`,
                note: this.special,
                city: this.city,
                state: this.state,
                zip_code: this.zip_code,
                street_address1: this.street_address1,
                street_address2: this.street_address2,
                total_item_count: this.total_food_count,
                total_price: this.order_price_total,
                tax: this.taxAndFee,
                tip: this.tip_price,
                delivery_fee: this.delivery_fee,
                discount: this.cart_price_savin,
                order_foods: this.cart_food_list,
                pay_method: this.pay_method,
                delivery_method: this.teamup.delivery_method,
                pickup_address: this.teamup.delivery_method == 'pickup' ? this.teamup.pickup_data[this.pickup_address_active] : '',
            }
            this.checkoutOrder(params)
        },
        async checkoutOrder(params) {
            if (this.pay_method === 'stripe') {
                const { token, error } = await this.stripe.createToken(this.card)
                if (error) {
                    this.set_loading(false)
                    this.set_msg({ msg: error.message })
                    this.stripe_card_errors = error.message
                    return
                } else {
                    params.stripe_token = token.id
                }
            }

            Dialog.confirm({
                title: '',
                message: this.$t('common.submitAll'),
                confirmButtonText: this.$t('common.confirm'),
                cancelButtonText: this.$t('common.cancel'),
            })
                .then((action) => {
                    if (action == 'confirm') {
                        this.createOrder(params).then((res) => {
                            if (res.code == 100000) {
                                this.claerCartData({})
                                this.setOrderId({ shared_oid: res.data })
                                this.set_msg({ msg: 'Submit successfully' })
                                this.$router.push({ path: '/review/' + res.data })
                            } else {
                                this.set_msg({ msg: res.msg })
                            }
                            this.set_loading(false)
                        })
                    }
                })
                .catch(() => {
                    this.set_loading(false)
                    // on cancel
                })
        },
        setStripe(data) {
            const { stripe, card } = data
            this.stripe = stripe
            this.card = card
            this.set_loading(false)
        },
        loadPaypal() {
            if (!window.paypal) {
                return
            }
            paypal
                .Buttons({
                    onClick: (data, actions) => {
                        if (this.cart_food_list.length < 1) {
                            this.set_msg({ msg: this.$t('common.noFood') })
                            return actions.reject()
                        }
                        if (!this.verifyData()) {
                            return actions.reject()
                        }
                        return actions.resolve()
                    },
                    createOrder: (data, actions) => {
                        let temp_food_list = JSON.parse(JSON.stringify(this.cart_food_list))
                        let item_arr = []

                        /*
                         * 增加支付订单的订单详情
                         * 将菜品和add-on分开展示
                         */
                        for (let i = 0, len = temp_food_list.length; i < len; i++) {
                            let item_price = temp_food_list[i].teamup_price

                            item_arr.push({
                                name: temp_food_list[i].fname,
                                quantity: temp_food_list[i].fcount,
                                unit_amount: {
                                    currency_code: 'USD',
                                    value: item_price * 1,
                                },
                            })

                            let _addon_names = temp_food_list[i].addon_names.replace(/\@\@/g, '|').split('|')
                            let _addon_prices = temp_food_list[i].addon_prices.replace(/\@\@/g, '|').split('|')
                            let _addon_counts = temp_food_list[i].addon_counts.replace(/\@\@/g, '|').split('|')
                            for (let j = 0; j < _addon_names.length; j++) {
                                if (_addon_names[j]) {
                                    item_arr.push({
                                        name: temp_food_list[i].fname + ' - ' + _addon_names[j],
                                        quantity: temp_food_list[i].fcount,
                                        unit_amount: {
                                            currency_code: 'USD',
                                            value: _addon_prices[j] * _addon_counts[j] || 0,
                                        },
                                    })
                                }
                            }
                        }

                        // 处理费单独计算
                        if (this.taxAndFee > 0) {
                            item_arr.push({
                                name: 'tax',
                                quantity: 1,
                                unit_amount: {
                                    currency_code: 'USD',
                                    value: this.taxAndFee,
                                },
                            })
                        }

                        if (this.delivery_fee > 0) {
                            item_arr.push({
                                name: 'delivery fee',
                                quantity: 1,
                                unit_amount: {
                                    currency_code: 'USD',
                                    value: this.delivery_fee,
                                },
                            })
                        }
                        if (this.tip_price > 0) {
                            item_arr.push({
                                name: 'tip',
                                quantity: 1,
                                unit_amount: {
                                    currency_code: 'USD',
                                    value: this.tip_price,
                                },
                            })
                        }
                        let _discount = 0

                        let _temp_price = this.order_price_total

                        return actions.order.create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: 'USD',
                                        value: _temp_price,
                                        breakdown: {
                                            item_total: {
                                                currency_code: 'USD',
                                                value: (_temp_price * 1 + _discount * -1).toFixed(2),
                                            },
                                            discount: {
                                                currency_code: 'USD',
                                                value: _discount * -1,
                                            },
                                        },
                                    },
                                    items: item_arr,
                                    description: this.store_info.sname + ' @@ ' + this.teamup.sid + ' @@ ' + this.teamup.eid,
                                },
                            ],
                            application_context: {
                                brand_name: this.teamup.sid + ' @@ ' + this.teamup.eid,
                            },
                        })
                    },
                    onApprove: (data, actions) => {
                        return actions.order.capture().then((details) => {
                            return this.paypal_checkout(data.orderID, details.purchase_units[0].payments.captures[0].id)
                        })
                    },
                    onError: (err) => {
                        console.log('输出 ~ err', err)
                    },
                })
                .render('#paypal-button-container')
        },
        paypal_checkout(paypal_orderid, capture_id) {
            this.set_loading(true)
            let params = {
                eid: this.teamup.eid,
                name: this.name,
                phone: `${this.areaCode} ${this.phone}`,
                note: this.special,
                city: this.city,
                state: this.state,
                zip_code: this.zip_code,
                street_address1: this.street_address1,
                street_address2: this.street_address2,
                total_item_count: this.total_food_count,
                total_price: this.order_price_total,
                tax: this.taxAndFee,
                tip: this.tip_price,
                delivery_fee: this.delivery_fee,
                discount: this.cart_price_savin,
                order_foods: this.cart_food_list,
                paypal_orderid: paypal_orderid,
                capture_id: capture_id,
                pay_method: 'paypal',
                delivery_method: this.teamup.delivery_method,
                pickup_address: this.teamup.delivery_method == 'pickup' ? this.teamup.pickup_data[this.pickup_address_active] : '',
            }
            return this.createOrder(params).then((res) => {
                if (res.code == 100000) {
                    this.claerCartData({})
                    this.setOrderId({ shared_oid: res.data })
                    this.set_msg({ msg: 'Submit successfully' })
                    this.$router.push({ path: '/review/' + res.data })
                } else {
                    this.set_msg({ msg: res.msg })
                }
                this.set_loading(false)
            })
        },
        previous() {
            this.$router.push({ path: '/cart/' + this.teamup.eid })
        },
    },
}
</script>
<style lang="scss" scoped>
.container {
    position: relative;
    padding-bottom: 100px;
}
.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    // width: 100%;
    height: 65px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwwAAADiBAMAAAAR910nAAAAGFBMVEUNqlE9uE8UrFA3t08xtVAps1AarlAisFC5cK/VAAAEDElEQVR42uTasU0EQBDF0G1hxXGk1EBAjKiAEiiB/iPa+LZ/C0+awJpzO/s+u7uZvZ7h3cx+z/BuZR9neTey6ZPUYfg707uNfZ7t3cQe72d7N7GfM75b2POs7wb2sn6SGgxfZ37Xv7ezv6vfy3TFyDAsh9UOw3jFqDCMV4wIw3ZYrTDMV4wGw3zFSDCsh9UGA6BiFBgoJ8nNQKgYfgZExfAzICqGnoFRMfQMoJMkZoBUDDnD46B2pWOEVTsDpmKoGShhVc7AqRhmBlDFEDNwwqqaAfAeFmDgnSQjAymsihlIYdXLgAqrXgZYxZAysMKqlQFXMZwMuIqhZKCFVScDsGIYGagnycVArBg+BmTF8DEgK4aOgVkxdAzgkyRigFYMGQPsPczKwAyrNgZsxVAxUMOqjIFbMUwM4IohYuCGVRUD8D1MyMA/SQYGclgVMZDDqocBHVY9DPCKIWFgh1ULAzysWhjwFUPBQA+rDgZBxTAwWE4Sm8FQMfgMiorBZ1BUDDyDo2LgGUQnCcwgqRhwBkvFgDM4wiqdQVMx0Az49zAHg6dikBlEFQPM4AmraAbBe5iAwXeSiAymsApmMIVVLoMqrHIZZBUDyuAKq1QGWVilMugqBpLheaS7pAkrBpHBepJYDMaKwWNQVgweg7Ji4BicFQPHID5JIAZpxYAxWCsGjMEZVmkMtvcwJoPuPYzJ4K0YJAZxxQAxeMMqikH4HgZk8J8kAoM5rIIYzGGVw6AOqxwGecWAMLjDKoVBHlYpDPqKgWDQvoehGAIVg8BQOUnbDIWKsc+QqBj7DImKMc/QqBjzDKGTNMwQqRjjDJWKMc7QCKvrDPb3MAaD/j2MwdCpGMsMoYoxzNAJq9MMgfcwAEPvJE0ypCrGLEMprO4ypMLqLkOsYowytMLqKkMsrK4y5CrGJEPmPWyaIVgxFhmqJ2mLoVgx9hhK72HDDMmKMcfQrBhzDOGTNMQQrRhjDNWKMcbQDKv/7dqxCQAADMOw/7/uCx0FcV8QdDDRGNbmYSbD3DzMZNitGBLDcMWAGHbDKsUwOA8DGXpJBMN0xWAYlsOqwzAdVh2G8YqBMGyHVYVhvmIYDPMVg2CYnYdRDFUMgqGXJDBUMQSG5XkYxFDFEBiqGARDL0lgqGIIDOPzMIWhsCowrM/DDIbCKsFQxRAYqhgCQ2GVYGgeJjD0kgiGKobAUFgVGAqrBEMVQ2AorAoMVQyCoYohMDQPExiqGARDL0lgqGIIDM3DCIYqhsBQxSAYekkCQxVDYGgeRjAUVgWG5mECQ2GVYKhiCAxVDIGhsEowNA8TGHpJBEMVQ2AorAoMhVWCoYohMBRWBYYqBsFQxRAYmocJDFUMgqGXJDBUMQSG5mEEQxXjfQfHTMCez/ccmAAAAABJRU5ErkJggg==);
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 28px;
    .iconfont {
        font-size: 30px;
    }
}
.broadcast {
    height: 22px;
    background: #f6bf9a;
    color: #ea5d00;
    // font-weight: 300;
    font-size: 10px;
    line-height: 22px;
}

.column {
    padding: 16px 22px;
    &.gery {
        background: #f4efed;
    }
    .content {
        flex-grow: 1;
    }
    .title {
        margin-bottom: 12px;
        font-weight: 700;
        font-size: 14px;
    }
    .border-textarea {
        box-sizing: border-box;
        width: 100%;
    }
    .border-input {
        flex-grow: 1;
        width: 100%;
    }
    .pay_info {
    }
    .info {
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        color: #93634c;
        .label {
            flex-shrink: 0;
            margin-right: 10px;
            width: 100px;
            text-align: right;
        }
        .add-address {
            box-sizing: border-box;
            padding-left: 16px;
            width: 100%;
            border: 1px solid #3db84f;
            border-radius: 7px;
            color: #3db84f;
            font-size: 16px;
            line-height: 35px;
        }
        .text {
            font-weight: 400;
            font-size: 16px;
            &.small {
                font-size: 12px;
            }
            &.address {
                text-decoration: underline;
            }
        }
        .check {
            padding: 1px 5px;
            border: 1px solid #3db84f;
            border-radius: 7px;
            color: #3db84f;
            font-weight: 300;
            font-size: 10px;
        }
        .phone {
            display: flex;
            .prefix {
                margin-right: 8px;
                width: 35px;
                height: 35px;
                border: 1px solid #3db84f;
                border-radius: 7px;
                color: #3db84f;
                text-align: center;
                line-height: 35px;
            }
        }
        .tip {
            display: flex;
            height: 40px;
            border: 1px solid #44ba56;
            border-radius: 6px;
            color: #44ba56;
            line-height: 40px;
            .tip-item {
                flex-grow: 1;
                border-radius: 4px;
                text-align: center;
                &.active {
                    background: #3db84f;
                    color: #fff;
                }
            }
        }
        .pay {
            display: flex;
            flex-wrap: wrap;
            .item {
                display: flex;
                align-items: center;
                flex-shrink: 0;
                flex-wrap: wrap;
                margin-right: 5px;
                margin-bottom: 10px;
                padding: 0 3px;
                height: 40px;
                border: 1px solid #44ba56;
                border-radius: 4px;
                color: #44ba56;
                line-height: 40px;
                .iconfont {
                    margin-right: 4px;
                }
                .icon-dollar {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    background: #44ba56;
                    color: #def2e0;
                    text-align: center;
                    line-height: 20px;
                }
                &.active {
                    background: #44ba56;
                    color: #fff;
                    .icon-dollar {
                        background: #def2e0;
                        color: #44ba56;
                    }
                    .icon-card {
                        color: #def2e0;
                    }
                }
            }
        }
    }
    .order {
        padding: 12px 24px;
        border-radius: 7px 7px 0 0;
        background: #f6f6f6;
        .item {
            margin-bottom: 12px;
        }
        .border {
            width: 100%;
            height: 1px;
            background: #979797;
        }
        .food {
            display: flex;
            // font-size: 14px;
            .name {
                font-weight: 700;
            }
            .num {
                margin-right: 4px;
                color: #44ba56;
                font-weight: 700;
            }
            .price {
                margin-left: auto;
                font-weight: 700;
            }
        }
        .remark {
            margin-top: 6px;
            padding-left: 10px;
            color: #999999;
        }
        .feature {
            color: #999999;
        }
        .addon {
            margin-top: 6px;
            padding-left: 10px;
            color: #999999;
        }
    }
}
.total {
    margin: -12px 18px 0;
    padding: 7px 14px;
    border-radius: 7px;
    box-shadow: 0px 0px 10px rgba(116, 116, 116, 0.2);
    .row {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 4px;
        color: #8f5f49;
        font-size: 14px;
        .content {
            margin-left: auto;
            &.no-right {
                margin-left: 0;
            }
            &.savin {
                margin-right: 6px;
                margin-left: auto;
                padding: 2px 6px;
                background: #e9dfda;
                color: #da352e;
                font-style: italic;
            }
        }
    }
}
.tip-pop {
    &::v-deep .van-popup {
        max-height: 400px;
    }
}
</style>
