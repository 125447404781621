<template>
    <div class="container">
        <div class="header">
            <div class="cart-icon">
                <span class="iconfont icon-cart" @click="previous"></span>
            </div>
            <div class="title">{{ $t('cart.cart') }}</div>
            <div class="card-icon">
                <span class="iconfont icon-card-o" @click="gotoCheckout"></span>
            </div>
        </div>
        <notice-bar class="broadcast" :text="trans(radioStr, 'str')" />
        <div class="list">
            <div class="item" v-for="(item, index) in cart_food_list" :key="index">
                <div class="wrap">
                    <div class="timg">
                        <img class="fullimg" :src="item.fpic || blankImg" alt="" />
                    </div>
                    <div class="info">
                        <div class="name">{{ translate('item', item.fname) }}</div>
                        <span class="feature" v-if="item.feature_name">({{ translate('feature_value', item.feature_name) }})</span>
                        <div class="addon" v-if="item.addon_names">
                            <template v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')">
                                <div class="addon-item" v-for="(item_name, item_index) in addon_name_item.split('|')" v-bind:key="item_name + '_item_val'">
                                    <span v-if="addon_name_item">
                                        {{ item.fcount * item.addon_counts.split('@@')[addon_index].split('|')[item_index] }} X {{ translate('addon_value', item_name) }}
                                    </span>
                                    (${{
                                        (item.fcount * item.addon_counts.split('@@')[addon_index].split('|')[item_index] * item.addon_prices.split('@@')[addon_index].split('|')[item_index]).toFixed(
                                            2
                                        )
                                    }})
                                </div>
                            </template>
                        </div>
                        <div class="note" v-if="item.note">{{ $t('review.note') }}: {{ item.note }}</div>
                        <div class="count">
                            <div class="price"><span class="iconfont icon-tag"></span>${{ (item.teamup_price * item.fcount).toFixed(2) }}</div>
                            <PlusMinus :count="item.fcount" :plusCb="() => change_food_count('plus', item)" :minusCb="() => change_food_count('minus', item)" />
                        </div>
                    </div>
                </div>
                <div class="border"></div>
            </div>
        </div>
        <div class="total">
            <div class="row">
                <div class="label">{{ $t('common.subtotal') }}</div>
                <div class="content savin">{{ $t('common.savin') }} $ {{ cart_price_savin }}</div>
                <div class="content no-right">$ {{ cart_price.toFixed(2) }}</div>
            </div>
            <div class="row">
                <div class="label">{{ $t('common.taxAndFee') }}</div>
                <div class="content">$ {{ taxAndFee }}</div>
            </div>
            <div class="row" style="font-weight: 700">
                <div class="label">{{ $t('common.estimatedTotal') }}</div>
                <div class="content">$ {{ cart_price_total }}</div>
            </div>
        </div>
        <div class="button-submit" @click="gotoCheckout">{{ $t('cart.proceedToCheckout') }}</div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import PlusMinus from '@/components/Plus_Minus'
import { NoticeBar } from 'vant'

export default {
    name: 'cart',
    data() {
        return {
            blankImg: require('../assets/images/fork.png'),
            radioStr: '',
            otherOrders: [],
        }
    },
    components: {
        NoticeBar,
        PlusMinus,
    },
    async created() {
        this.set_loading(true)
        let { eid } = this.$route.params
        if (!eid) {
            this.$router.push({ path: '/welcome' })
            return
        }
        this.set_eid({ eid: eid })
        await this.initData({ params: { eid: eid }, router: this.$router })
        this.queryOrderShow({ eid }).then((res) => {
            let orderShow = res.data.order
            let radioStr = ''
            let radioStr_chn = ''
            let radioStr_kor = ''
            let otherOrders = []
            for (let i = 0; i < orderShow.length; i++) {
                const item = orderShow[i]
                let foodStr = ''
                let foodStr_chn = ''
                let foodStr_kor = ''
                let foods = JSON.parse(item.item_detail)
                foods.forEach((e) => {
                    foodStr += `${this.translate('item', e.fname, 'eng')} X ${e.count}, `
                    foodStr_chn += `${this.translate('item', e.fname, 'chn')} X ${e.count}, `
                    foodStr_kor += `${this.translate('item', e.fname, 'kor')} X ${e.count}, `
                })
                radioStr += `#${item.id} ${item.name} joined & ordered ${foodStr};`
                radioStr_chn += `#${item.id} ${item.name} 入店 并 下单 ${foodStr_chn};`
                radioStr_kor += `#${item.id} ${item.name} 入店 并 下单 ${foodStr_kor};`
                otherOrders.push({
                    id: item.id,
                    name: item.name,
                    foods: foods,
                })
            }
            // this.set_radio_str(radioStr)
            this.radioStr = {
                str: radioStr,
                str_chn: radioStr_chn,
                str_kor: radioStr_kor,
            }
            this.otherOrders = otherOrders
            // this.set_other_orders(otherOrders)
            this.set_loading(false)
        })
        // this.set_loading(false)
    },
    watch: {
        store_info() {
            if (this.wechat) {
                let link = `https://${window.location.host}/dist/#/cart/${this.teamup.eid}`
                let title = `${this.teamup.event_name}-${this.store_info.sname}`
                this.wechat.share(title, this.teamup.event_description, link, this.store_info.slogo)
            }
        },
    },
    mounted() {},
    computed: {
        ...mapGetters({
            cart_food_list: 'app/get_cart_food_list',
            cart_food_dict: 'app/get_cart_food_dict',
            menu_dict: 'app/get_menu_dict',
            teamup: 'app/get_teamup',
            store_info: 'app/get_store_info',
            cart_price: 'app/get_cart_price',
            tax: 'app/get_tax',
            cart_price_savin: 'app/get_cart_price_savin',
            language: 'app/get_language',
            translate: 'app/translate',
            // radioStr: 'order/get_radioStr',
        }),

        taxAndFee() {
            return this.cart_price_tax
        },
        cart_price_tax() {
            let _tax = (this.cart_price * this.tax).toFixed(2) * 1
            return _tax < 0 ? 0 : _tax
        },
        cart_price_fee() {
            return this.cart_price.toFixed(2) * 1
        },
        cart_price_total() {
            let _price = (1 * (this.cart_price + this.cart_price_tax)).toFixed(2)
            return _price < 0 ? 0 : _price
        },
    },
    methods: {
        ...mapActions({
            initData: 'app/initData',
            edit_cart_food_count: 'app/edit_cart_food_count',
            queryOrderShow: 'order/queryOrderShow',
        }),
        ...mapMutations({
            set_loading: 'app/SET_LOADING',
            set_msg: 'app/APP_SET_MSG',
            set_eid: 'app/SET_EID',
            // set_other_orders: 'order/SET_OTHER_ORDERS',
        }),
        async change_food_count(action, food) {
            const mix_fcount = this.cart_food_dict[food.fid]?.mix_fcount || 0
            let newCount = food.fcount
            let _food_data = JSON.parse(JSON.stringify(food))
            let typeInMsg = ''
            typeInMsg = action === 'plus' ? this.$t('common.add') : this.$t('common.reduce')
            if (action == 'minus' && newCount == 1) {
                typeInMsg = this.$t('common.delete')
            }
            if (action == 'plus') {
                if (_food_data.once_limit > 0 && mix_fcount + 1 > _food_data.once_limit) {
                    this.set_msg({
                        msg: this.$t('common.exceedOrder'),
                    })
                    return
                }
                if (_food_data.teamup_limit > 0 && mix_fcount + +1 > _food_data.teamup_limit - _food_data.teamup_sales_count) {
                    this.set_msg({
                        msg: this.$t('common.exceedStock'),
                    })
                    return
                }
                newCount++
            } else if (action == 'minus') {
                newCount--
            }

            _food_data.fcount = newCount

            let save_data = {
                cartid: this.$store.state.app.shared_cart_id,
                tid: this.$store.state.app.shared_tid,
                uid: this.$store.state.app.email,
                uname: this.$store.state.app.uname,
                router: this.$router,
                type: 'set',
                fid: _food_data.fid,
                fname: this.translate('item', _food_data.fname),
                // fname_chn: _food_data.fname_chn,
                fprice: _food_data.fprice,
                teamup_price: _food_data.teamup_price,
                feature_name: _food_data.feature_name,
                note: _food_data.note,
                addon_names: _food_data.addon_names,
                // addon_counts: _food_data.addon_counts || 0,
                addon_prices: _food_data.addon_prices,
                total_price: this.get_total_price({ food, count: newCount }),
                set_count: newCount,
                typeInMsg,
                this: this,
            }
            await this.edit_cart_food_count(save_data)
            this.$parent.$forceUpdate()
        },
        get_total_price({ food, count }) {
            let addon_prices = food.addon_prices.split('@@')
            let addon_counts = food.addon_counts.split('@@')
            let extra_price = 0
            if (food.addon_prices) {
                for (let i = 0, len = addon_prices.length; i < len; i++) {
                    let items = addon_prices[i].split('|')
                    let counts_arr = addon_counts[i].split('|')
                    for (let j = 0; j < items.length; j++) {
                        extra_price = parseFloat(extra_price) + parseFloat(items[j] * counts_arr[j])
                    }
                }
            }

            if (count > 0) {
                let _price = food.teamup_price
                return ((parseFloat(_price) + extra_price) * count).toFixed(2)
            }

            return 0
        },
        gotoCheckout() {
            if (this.cart_food_list.length < 1) {
                this.set_msg({ msg: this.$t('common.noFood') })
                return
            }
            this.$router.push({ path: '/checkout/' + this.teamup.eid })
        },
        previous() {
            this.$router.push({ path: '/menu/' + this.teamup.eid })
        },
    },
}
</script>
<style lang="scss" scoped>
.container {
    position: relative;
    padding-bottom: 100px;
    min-height: 100vh;
}
.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    // width: 100%;
    height: 65px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwwAAADiBAMAAAAR910nAAAAGFBMVEUNqlE9uE8UrFA3t08xtVAps1AarlAisFC5cK/VAAAEDElEQVR42uTasU0EQBDF0G1hxXGk1EBAjKiAEiiB/iPa+LZ/C0+awJpzO/s+u7uZvZ7h3cx+z/BuZR9neTey6ZPUYfg707uNfZ7t3cQe72d7N7GfM75b2POs7wb2sn6SGgxfZ37Xv7ezv6vfy3TFyDAsh9UOw3jFqDCMV4wIw3ZYrTDMV4wGw3zFSDCsh9UGA6BiFBgoJ8nNQKgYfgZExfAzICqGnoFRMfQMoJMkZoBUDDnD46B2pWOEVTsDpmKoGShhVc7AqRhmBlDFEDNwwqqaAfAeFmDgnSQjAymsihlIYdXLgAqrXgZYxZAysMKqlQFXMZwMuIqhZKCFVScDsGIYGagnycVArBg+BmTF8DEgK4aOgVkxdAzgkyRigFYMGQPsPczKwAyrNgZsxVAxUMOqjIFbMUwM4IohYuCGVRUD8D1MyMA/SQYGclgVMZDDqocBHVY9DPCKIWFgh1ULAzysWhjwFUPBQA+rDgZBxTAwWE4Sm8FQMfgMiorBZ1BUDDyDo2LgGUQnCcwgqRhwBkvFgDM4wiqdQVMx0Az49zAHg6dikBlEFQPM4AmraAbBe5iAwXeSiAymsApmMIVVLoMqrHIZZBUDyuAKq1QGWVilMugqBpLheaS7pAkrBpHBepJYDMaKwWNQVgweg7Ji4BicFQPHID5JIAZpxYAxWCsGjMEZVmkMtvcwJoPuPYzJ4K0YJAZxxQAxeMMqikH4HgZk8J8kAoM5rIIYzGGVw6AOqxwGecWAMLjDKoVBHlYpDPqKgWDQvoehGAIVg8BQOUnbDIWKsc+QqBj7DImKMc/QqBjzDKGTNMwQqRjjDJWKMc7QCKvrDPb3MAaD/j2MwdCpGMsMoYoxzNAJq9MMgfcwAEPvJE0ypCrGLEMprO4ypMLqLkOsYowytMLqKkMsrK4y5CrGJEPmPWyaIVgxFhmqJ2mLoVgx9hhK72HDDMmKMcfQrBhzDOGTNMQQrRhjDNWKMcbQDKv/7dqxCQAADMOw/7/uCx0FcV8QdDDRGNbmYSbD3DzMZNitGBLDcMWAGHbDKsUwOA8DGXpJBMN0xWAYlsOqwzAdVh2G8YqBMGyHVYVhvmIYDPMVg2CYnYdRDFUMgqGXJDBUMQSG5XkYxFDFEBiqGARDL0lgqGIIDOPzMIWhsCowrM/DDIbCKsFQxRAYqhgCQ2GVYGgeJjD0kgiGKobAUFgVGAqrBEMVQ2AorAoMVQyCoYohMDQPExiqGARDL0lgqGIIDM3DCIYqhsBQxSAYekkCQxVDYGgeRjAUVgWG5mECQ2GVYKhiCAxVDIGhsEowNA8TGHpJBEMVQ2AorAoMhVWCoYohMBRWBYYqBsFQxRAYmocJDFUMgqGXJDBUMQSG5mEEQxXjfQfHTMCez/ccmAAAAABJRU5ErkJggg==);
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 28px;
    .iconfont {
        font-size: 30px;
    }
}
.broadcast {
    height: 22px;
    background: #f6bf9a;
    color: #ea5d00;
    // font-weight: 300;
    font-size: 10px;
    line-height: 22px;
}
.list {
    margin-top: 16px;
    padding: 0 18px;

    .item {
        box-sizing: border-box;
        padding: 10px;
        width: 100%;
        background: rgba(216, 216, 216, 0.2);
        &:last-child .border {
            display: none;
        }
        &:first-child {
            border-radius: 0px 7px 0 0;
        }
        &:last-child {
            border-radius: 0 0 7px 7px;
        }
        .wrap {
            display: flex;
        }
        .border {
            margin: 10px 10px 0;
            height: 1px;
            background: rgba(143, 95, 73, 0.37);
        }

        .timg {
            flex: 0 0 58px;
            flex-shrink: 0;
            margin-right: 10px;
            height: 58px;
        }

        .info {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            justify-content: space-between;
            min-width: 0;
            .name {
                overflow: hidden;
                color: #93634c;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: 500;
                font-size: 18px;
            }
            .feature {
                color: #999;
            }
            .addon {
                margin-top: 10px;
                color: #999;
                font-size: 16px;
                .addon-item {
                    margin-bottom: 6px;
                }
            }
            .note {
                color: #999;
                font-size: 16px;
            }
            .count {
                display: flex;
                align-items: flex-end;
                flex-grow: 1;
                justify-content: space-between;
                width: 100%;
                .price {
                    color: #da352e;
                    font-weight: 700;
                    font-size: 18px;
                    .iconfont {
                        margin-right: 2px;
                        color: #ffc008;
                    }
                }
            }
        }
    }
}
.total {
    margin: 0 18px;
    padding: 7px 14px;
    border-radius: 7px;
    box-shadow: 0px 0px 10px rgba(116, 116, 116, 0.2);
    .row {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 4px;
        color: #8f5f49;
        font-size: 14px;
        .content {
            margin-left: auto;
            &.no-right {
                margin-left: 0;
            }
            &.savin {
                margin-right: 6px;
                margin-left: auto;
                padding: 2px 6px;
                background: #e9dfda;
                color: #da352e;
                font-style: italic;
            }
        }
    }
}
</style>
