import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import createPersistedState from 'vuex-persistedstate'

import app from './modules/app'
import menu from './modules/menu'
import order from './modules/order'
import Food from './modules/food'

Vue.use(Vuex)

let state = {
    debug: '',
    is_development: '',
    aj_host: '',
}
let debug = window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost' || window.location.href.indexOf('http://1') === 0
let is_development = debug || window.location.hostname === 'teamup.minitable.link'
let aj_host = debug ? 'http://127.0.0.1:7373' : ''
const mutations = {
    SET_DEBUG(state, data) {
        state.debug = data
    },
    SET_IS_DEVELOMENT(state, data) {
        state.is_development = data
    },
    SET_AJ_HOST(state, data) {
        state.aj_host = data
    },
}
const getters = {
    serverRoot(state) {
        const localServerPort = '5966'
        const { hostname, protocol } = window.location
        return `${protocol}//${hostname}${state.debug ? ':' + localServerPort : ''}`
    },
}
let plugins = [
    createPersistedState({
        // 本地存储数据的键名
        key: 'cart_data_all_store',
        // 指定需要存储的模块，如果是模块下具体的数据需要加上模块名称，如user.token
        paths: ['app.cart_data_all_store'],
    }),
]
if (debug) {
    // plugins.push(createLogger())
}
let store = new Vuex.Store({
    modules: {
        app,
        menu,
        order,
        Food,
    },
    state,
    getters,
    mutations,
    strict: debug,
    plugins: plugins,
    // plugins: debug ? [createLogger(), createPersistedState()] : [createPersistedState()],
})
store.commit('SET_DEBUG', debug)
store.commit('SET_IS_DEVELOMENT', is_development)
store.commit('SET_AJ_HOST', aj_host)

export default store
