require('es6-promise').polyfill() //es6 promise
require('isomorphic-fetch') //fetch
import { setRemInit } from './rem'

import Vue from 'vue'
import App from './views/App.vue'
import store from './store/index.js'
import router from './router'
import VueLazyload from 'vue-lazyload'
import '@/assets/icon/iconfont.css'
import moment from 'moment-timezone'
import _ from 'lodash'
import i18n from './locales'
import { trans, transNames } from './locales'
import { Wechat } from './kits/wechat'

Vue.prototype._ = _
Vue.prototype.trans = trans
Vue.prototype.transNames = transNames
Vue.prototype.$moment = moment
setRemInit()

let wechat = new Wechat()
if (wechat.isWeiXin) {
    Vue.prototype.wechat = wechat
}

Vue.use(VueLazyload, {
    preLoad: 1.3,
    attempt: 1,
})

export function createApp() {
    const app = new Vue({
        el: '#application',
        router,
        store,
        i18n,
        render: (h) => h(App),
    })

    return { app, router }
}

window.onload = () => createApp()
