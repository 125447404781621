import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import Welcome from './views/Welcome.vue'
import Menu from './views/menu.vue'
import Cart from './views/cart.vue'
import Checkout from './views/checkout.vue'
import Review from './views/review.vue'
Vue.use(Router)

const router = new Router({
    mode: 'hash',
    linkExactActiveClass: 'is-active',
    scrollBehavior: () => ({ y: 0 }),
    routes: [
        { path: '/', component: Menu },
        { path: '/welcome/:type?', component: Welcome },
        { path: '/menu/:eid?', component: Menu },
        { path: '/cart/:eid?', component: Cart },
        { path: '/checkout/:eid?', component: Checkout },
        { path: '/review/:oid?', component: Review },
        { path: '*', redirect: '/welcome' },
    ],
})

router.beforeEach(async (to, from, next) => {
    //to prevent loading animation keeps running when switching router
    store.commit('app/SET_LOADING', false)
    next()
})

export default router
