<template>
    <div style="padding: 10px 0" id="stripe-container"></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
export default {
    name: 'Stripe',
    data() {
        return {
            stripe: null,
            card: null,
            stripe_card_errors: '',
        }
    },
    beforeCreate() {
        if (document.querySelector('#stripe-script')) {
            return
        }
        const js = 'https://js.stripe.com/v3/'
        const script = document.createElement('script')
        script.src = js
        script.id = 'stripe-script'
        document.head.appendChild(script)
    },
    beforeDestroy() {
        const stripeEl = document.querySelector('#stripe-container')
        if (stripeEl) {
            stripeEl.innerHTML = ''
        }
    },
    computed: {
        ...mapState(['is_development']),
        ...mapGetters({
            language: 'app/get_language',
        }),
        apiKey() {
            return this.is_development ? 'pk_test_03Chui06CyQnV8X5D8McRhsy00iFo0AE1i' : 'pk_live_pSSQV1ecnym115TkBiNYOa0G004fsK7ehA'
        },
    },
    mounted() {
        // wait for stripe script loads
        let stripeInterval = setInterval(() => {
            if (window.Stripe) {
                clearInterval(stripeInterval)
                this.build_stripe_elements()
            }
        })
    },

    methods: {
        build_stripe_elements() {
            this.stripe = Stripe(this.apiKey)
            this.elements = this.stripe.elements({
                locale: this.language == 'eng' ? 'en' : 'zh',
            })

            const style = {
                base: {
                    color: '#32325d',
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '12px',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            }

            this.card = this.elements.create('card', { style })
            this.card.mount('#stripe-container')

            this.card.addEventListener('change', ({ error }) => {
                if (error) {
                    this.stripe_card_errors = error.message
                } else {
                    this.stripe_card_errors = ''
                }
            })

            this.$emit('setStripe', { stripe: this.stripe, card: this.card })
            // const paymentRequest = this.stripe.paymentRequest({
            //     country: 'US',
            //     currency: 'usd',
            //     total: {
            //         label: 'total',
            //         amount: 1
            //         // amount: this.is_vip ? (1 * (this.cart_price_vip + this.cart_price_vip_tax)).toFixed(2) : (1 * (this.cart_price + this.cart_price_tax)).toFixed(2),
            //     },
            //     requestPayerName: true,
            //     requestPayerEmail: true,
            // });

            // const prButton = this.elements.create('paymentRequestButton', {
            //     paymentRequest,
            // });

            // (async () => {
            //     const result = await paymentRequest.canMakePayment();
            //     console.log(result);
            //     if (result) {
            //         prButton.mount('#payment-request-button');
            //     } else {
            //         document.getElementById('payment-request-button').style.display = 'none';
            //     }
            // })();

            // paymentRequest.on('token', async (ev) => {
            //     // Send the token to your server to charge it!
            //     console.log(ev.token.id);
            // });
        },
    },
}
</script>

<style lang="scss" scoped></style>
