import { isValidStr } from '../../kits/tools'
import axiosApi from '../../kits/axiosApi'
import moment from 'moment-timezone'
const _ = require('lodash')

// initial state
const state = {
    // eid: '',
    teamup: {},
    pickupAddressActive: 0,
    store_info: {},
    timezone: 'America/New_York',
    loading: false,
    sname: '',
    uname: window.localStorage.getItem('uname') || '未知',
    email: '',
    tel: '',
    store_data: {},
    language: window.localStorage.getItem('language') || '',
    cart_data_all_store: {},
    cart_data: {
        cart_food_list: [],
        cart_food_dict: {
            random: new Date().getTime(),
        },
        total_price: '0.0',
        total_price_vip: '0.0',
        remark: '',
        user_count: 0,
        total_food_count: 0,
        total_price_pickup: 0,
        total_price_savin: 0,
    },
    shared_tid: window.localStorage.getItem('shared_tid') || '',
    shared_tstatus: '',
    shared_oid: window.localStorage.getItem('shared_oid') || '',
    shared_cart_id: window.localStorage.getItem('shared_cart_id') || '',
    shared_foods_timer: -1,
    msg: '',
    has_new_order: false,
    show_cart_detail: false,
    show_left_nav: false,
    app_title: '',
    show_menu_nav: false,
    is_vip: 1,
    pay_method_setting: '',
    is_open: 0,
    err_msg: '',
    check_timer: -1,
    tip_method_setting: '',

    delivery_enable: 1,
    delivery_vip_free: 0,
    delivery_area_list: [],

    full_coupon: [],
    first_discount_amount: 0,
    first_discount_limit_amount: 0,
    first_discount_type: 'fixed',
    is_new: false,

    pickup_enable: 1,
    pickup_amount: 0,
    is_vip_new: false,
    merchant_custom_value: 0,
    phone_num: '',
    code: '+1',
    annoucement: '',
    pickup_discount_enable: 0,

    show_food_list: [],
    now_menu: '',
    menu_dict: {},
    menu_list: [],
    food_dict: {},
    menu_timer: -1,

    choose_time_options: '',
    choose_time_ahead_days: 0,
    choose_time: 'ASAP',

    is_loading: false,

    feature_image: '',
    joinin_back_color: '#E90011',
    notice_layer_title_color: '#E90011',
    notice_layer_subtitle: '',
    notice_layer_ps_content: '',

    pickup_setting: {},
    delivery_setting: {},

    type: '',
    state: '',
    city: '',
    street_address1: '',
    street_address2: '',
    zip_code: '',
    zip_code_index: -1,
    is_loading_menu: true,

    recommend_popup_setting: {
        recommend_popup_enable: 0,
        recommend_popup_name: '',
        recommend_popup_pic_url: '',
    },
    gid: localStorage.gid || '',
    dinein_setting: null,
    dineInTable: '',
    multi_lang: {},
}

// getters
const getters = {
    get_eid(state) {
        return state.eid
    },
    get_loading(state) {
        return state.loading || false
    },
    get_teamup(state) {
        if (JSON.stringify(state.teamup) == '{}') {
            return state.teamup
        }
        let newTeamup = _.cloneDeep(state.teamup)
        let now = moment()
        let endTime = moment(Number(newTeamup.end_time))
        newTeamup.duration = endTime.diff(now)
        newTeamup.delivery_data = JSON.parse(newTeamup.delivery_data)
        newTeamup.pickup_data = newTeamup.pickup_data ? JSON.parse(newTeamup.pickup_data) : []
        newTeamup.delivery_data = newTeamup.delivery_data.map((e) => {
            e.zip = e.zip_code_list.split(',')
            return e
        })
        return newTeamup
    },
    get_pickup_address_active(state) {
        return state.pickupAddressActive
    },
    get_store_info(state) {
        return state.store_info
    },
    get_timezone(state) {
        return state.timezone || 'America/New_York'
    },
    get_canDineIn(state) {
        if (!state.dinein_setting?.enable) return
        const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
        let now = new Date()
        let day = now.getDay()
        let hour = now.getHours()
        let minute = now.getMinutes()
        hour = hour < 10 ? '0' + hour : hour
        minute = minute < 10 ? '0' + minute : minute
        let hourMinute = hour + ':' + minute

        day = days[day]
        const enableTime = state.dinein_setting.enable_time[day]
        if (!enableTime || !enableTime.enable || !enableTime.time_list) return false
        const timeList = enableTime.time_list
        const result = timeList.some((item) => {
            const start = item.stime
            const end = item.etime
            if (hourMinute >= start && hourMinute <= end) {
                return true
            }
        })
        return result
    },
    get_dineInTables(state) {
        let tables = state.dinein_setting?.table_list || []
        tables = tables.filter((table) => {
            return table.enable == 1
        })
        return tables
    },
    get_dineInTableEnable(state, getters) {
        return state.dinein_setting?.enable_table_select == 1 && getters.get_dineInTables.length > 0
    },
    get_recommend_popup_setting(state) {
        return state.recommend_popup_setting
    },
    get_type(state) {
        return state.type
    },
    get_pickup_setting(state) {
        return state.pickup_setting
    },
    get_delivery_setting(state) {
        return state.delivery_setting
    },
    get_theme_data(state) {
        return {
            feature_image: state.feature_image,
            joinin_back_color: state.joinin_back_color,
            notice_layer_title_color: state.notice_layer_title_color,
            notice_layer_subtitle: state.notice_layer_subtitle,
            notice_layer_ps_content: state.notice_layer_ps_content,
        }
    },
    get_cart_price_pickup(state) {
        return state.cart_data.total_price_pickup * 1
    },
    get_pickup_discount_enable(state) {
        return state.pickup_setting.discount_enable * 1 || 0
    },
    get_if_loading(state) {
        return state.is_loading
    },
    get_choose_time(state) {
        return state.choose_time
    },
    get_choose_time_ahead_days(state) {
        return state.choose_time_ahead_days
    },
    get_choose_time_options(state) {
        return state.choose_time_options
    },
    get_show_food_list(state) {
        return state.show_food_list
    },
    get_now_menu(state) {
        return state.now_menu
    },
    get_menu_dict(state) {
        return state.menu_dict
    },
    get_menu_list(state) {
        return state.menu_list
    },
    get_food_dict(state) {
        return state.food_dict
    },
    get_annoucement(state) {
        return state.annoucement
    },
    get_is_vip_new(state) {
        return state.is_vip_new
    },
    get_is_open(state) {
        return state.is_open
    },
    get_err_msg(state) {
        return state.err_msg
    },
    get_paymethod_setting(state) {
        return state.pay_method_setting
    },
    get_if_is_vip(state) {
        return state.is_vip
    },
    get_if_can_place_order(state) {
        return state.store_data.can_place_order || 0
    },
    get_tax(state) {
        return state.store_info.tax || 0
    },
    get_if_show_menu_nav(state) {
        return state.show_menu_nav
    },
    get_app_title(state) {
        return state.app_title
    },
    get_if_show_left_nav(state) {
        return state.show_left_nav
    },
    get_if_has_new_order(state) {
        return state.has_new_order
    },
    get_store_name(state) {
        return state.sname || ''
    },
    get_store_pic(state) {
        return state.store_data.spic || ''
    },
    get_food_count(state) {
        return state.cart_data.total_food_count
    },
    get_cart_food_list(state) {
        return state.cart_data.cart_food_list
    },
    get_cart_price(state) {
        return state.cart_data.total_price * 1
    },
    get_cart_price_savin(state) {
        return state.cart_data.total_price_savin * 1 > 0 ? state.cart_data.total_price_savin * 1 : 0
    },
    get_cart_price_vip(state) {
        return state.cart_data.total_price_vip * 1
    },
    get_cart_food_dict(state) {
        return state.cart_data.cart_food_dict
    },
    get_shared_user_count(state) {
        return state.cart_data.user_count
    },
    get_shared_tname(state) {
        return state.shared_tname
    },
    get_shared_tstatus(state) {
        return state.shared_tstatus
    },
    get_shared_tid(state) {
        return state.shared_tid
    },
    get_shared_oid(state) {
        return state.shared_oid
    },
    get_app_msg(state) {
        return state.msg
    },
    get_store_slogo(state) {
        return state.store_data.slogo || ''
    },
    get_language(state) {
        return state.language || 'eng'
    },
    get_processing_fee(state) {
        return state.store_data.processing_fee || 0
    },
    get_processing_extra(state) {
        return state.store_data.processing_extra || 0
    },
    get_delivery_data(state) {
        return {
            delivery_enable: state.delivery_enable || 0,
            delivery_area_list: state.delivery_area_list || [],
            delivery_vip_free: state.delivery_vip_free || 0,
        }
    },
    get_promotion_data(state) {
        return {
            full_coupon: state.full_coupon,
            first_discount_amount: state.first_discount_amount,
            first_discount_type: state.first_discount_type,
            first_discount_limit_amount: state.first_discount_limit_amount,
        }
    },
    get_is_new(state) {
        return state.is_new
    },
    get_tip_method_setting(state) {
        return state.tip_method_setting
    },
    get_pickup_data(state) {
        return {
            pickup_enable: state.pickup_enable || 0,
            pickup_amount: state.pickup_amount || 0,
        }
    },
    get_merchant_custom_value(state) {
        return state.merchant_custom_value
    },
    translate(state) {
        return function (business, key, language = '') {
            let languageActive = language || state.language
            return state.multi_lang?.[business]?.[key]?.[languageActive] || key
        }
    },
}

// actions
const actions = {
    initData({ commit, state }, { params, router }) {
        return new Promise((resolve, reject) => {
            if (state.teamup.eid) {
                return resolve()
            }
            axiosApi
                .get('/weapp/teamup/event/query_teamup', { params })
                .then((res) => {
                    let data = res.data.data

                    let _paypal_client_id = data.data.paypal_client_id || ''
                    if (_paypal_client_id) {
                        const head = document.getElementsByTagName('head')[0]
                        let script = document.createElement('script')
                        script.type = 'text/javascript'
                        script.src = 'https://www.paypal.com/sdk/js?client-id=' + _paypal_client_id + '&disable-funding=card'
                        head.appendChild(script)
                    }

                    if (data.code === 100000) {
                        if (data.data.store_info.teamup_enable == 0) {
                            router.push({ path: '/welcome/1' })
                        }
                        commit('INIT_MENU_DATA', {
                            show_food_list: data.data.menu[data.data.first_menu].list,
                            now_menu: data.data.first_menu,
                            menu_dict: data.data.menu,
                            menu_list: data.data.menu_list,
                            food_dict: data.data.foods,
                            teamup: data.data.teamup,
                            store_info: data.data.store_info,
                        })
                        commit('APP_SET_MULTI_LANG', data.data.multi_lang)

                        if (!state.language) {
                            commit('APP_SET_LANGUAGE', {
                                language: data.data.store_info.language,
                            })
                        }
                    }
                    resolve(data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    quitTable({ commit, rootState }, post_data) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : ''
        fetch(aj_host + '/weapp/takeout/sharedcart/quit?tid=' + post_data.tid + '&cartid=' + post_data.cartid + '&sid=' + post_data.sid, {
            method: 'GET',
        })
            .then((res) => {
                return res.text()
            })
            .then((res) => {
                res = JSON.parse(res)
                if (res.data.code === 100000) {
                    commit('APP_SHARED_CART_CLOSED', {
                        router: post_data.router,
                        sid: post_data.sid,
                    })
                }
            })
    },

    edit_cart_food_count({ state, commit, rootState }, post_data) {
        // if (post_data.cartid == '' && post_data.router) {
        //     post_data.router.replace({ path: '/welcome' })
        // }
        commit('APP_SET_MSG', {
            msg: `${post_data.typeInMsg} ${post_data.fname} ${post_data.this.$t('common.setFoodSuccessfully')}`,
        })
        commit('APP_NEW_SET_FOOD', post_data)
        // let params =
        //     '' +
        //     'cartid=' +
        //     post_data.cartid +
        //     '&tid=' +
        //     post_data.tid +
        //     '&uid=' +
        //     post_data.uid +
        //     '&uname=' +
        //     post_data.uname +
        //     '&fid=' +
        //     post_data.fid +
        //     '&fprice=' +
        //     post_data.fprice +
        //     '&fprice_vip=' +
        //     post_data.fprice_vip +
        //     '&fprice_pickup=' +
        //     post_data.fprice_pickup +
        //     '&is_vip=' +
        //     post_data.is_vip +
        //     '&fcount=' +
        //     post_data.set_count +
        //     '&type=set' +
        //     '&feature_name=' +
        //     encodeURIComponent(post_data.feature_name) +
        //     '&addon_names=' +
        //     encodeURIComponent(post_data.addon_names) +
        //     '&addon_counts=' +
        //     encodeURIComponent(post_data.addon_counts) +
        //     '&addon_prices=' +
        //     post_data.addon_prices +
        //     '&note=' +
        //     encodeURIComponent(post_data.note)

        // // 产品要求提交完，马上关闭详情层
        // setTimeout(function () {
        //     commit('APP_TOGGLE_CART_DETAIL', false)
        // }, 100)

        // fetch(aj_host + '/weapp/takeout/sharedcart/edit?' + params, {
        //     method: 'GET',
        // })
        //     .then((res) => {
        //         return res.text()
        //     })
        //     .then((res) => {
        //         res = JSON.parse(res)
        //         if (res.data.code === 100000) {
        //             commit('APP_SET_MSG', {
        //                 msg: 'set "' + post_data.fname + '" successfully',
        //             })
        //             commit('APP_NEW_SET_FOOD', post_data)
        //         } else if (res.data.code === 100010) {
        //             // 共享购物车已关闭
        //             commit('APP_SHARED_CART_CLOSED', {
        //                 router: post_data.router,
        //                 sid: post_data.sid,
        //             })
        //         } else {
        //             commit('APP_SET_MSG', {
        //                 msg: 'set "' + res.data.msg + '" successfully',
        //             })
        //         }
        //     })
    },
    edit_cart_food({ state, commit, rootState, rootGetters }, post_data) {
        let aj_host = rootGetters.serverRoot

        post_data.food_data.uid = post_data.uid
        post_data.food_data.uname = post_data.uname
        post_data.food_data.feature_name = post_data.extra_data.feature_name
        post_data.food_data.feature_name_chn = post_data.extra_data.feature_name_chn
        post_data.food_data.addon_names = post_data.extra_data.addon_names
        post_data.food_data.addon_names_chn = post_data.extra_data.addon_names_chn
        post_data.food_data.addon_prices = post_data.extra_data.addon_prices
        post_data.food_data.addon_counts = post_data.extra_data.addon_counts
        post_data.food_data.addon_pickup_discounts = post_data.extra_data.addon_pickup_discounts
        post_data.food_data.note = post_data.extra_data.note
        post_data.food_data.add_count = post_data.extra_data.add_count
        post_data.food_data.addon_total_price = post_data.extra_data.addon_total_price
        post_data.food_data.addon_total_price_pickup = post_data.extra_data.addon_total_price_pickup
        post_data.food_data.is_vip = post_data.is_vip
        post_data.food_data.once_limit = post_data.extra_data.once_limit
        post_data.food_data.teamup_limit = post_data.extra_data.teamup_limit

        let check_food_data = JSON.parse(JSON.stringify(post_data.food_data))

        const { isAdd, food_data } = _checkAdd(check_food_data, rootState)
        if (!isAdd) {
            post_data.type = 'set'
            post_data.food_data.fcount = food_data.fcount
            post_data.food_data.set_count = food_data.fcount
        }

        // 产品要求提交完，马上关闭详情层
        setTimeout(function () {
            commit('Food/TOGGLE_PAGE', false, { root: true })
        }, 100)
        if (post_data.type == 'add') {
            commit('APP_SET_MSG', {
                msg: `${post_data.this.$t('common.add')} ${post_data.fname} ${post_data.this.$t('common.setFoodSuccessfully')}`,
            })
            commit('APP_NEW_ADD_FOOD', post_data.food_data)
        } else if (post_data.type == 'set') {
            commit('APP_SET_MSG', {
                msg: `${post_data.this.$t('common.set')} ${post_data.fname} ${post_data.this.$t('common.setFoodSuccessfully')}`,
            })
            commit('APP_NEW_SET_FOOD', post_data.food_data)
        } else {
            commit('APP_DEL_FOOD_INTO_CART', post_data.food_data)
            commit('APP_SET_MSG', {
                msg: `${post_data.this.$t('common.delete')} ${post_data.fname} ${post_data.this.$t('common.setFoodSuccessfully')}`,
            })
        }
        // let params =
        //     '' +
        //     'cartid=' +
        //     post_data.cartid +
        //     '&tid=' +
        //     post_data.tid +
        //     '&uid=' +
        //     post_data.uid +
        //     '&uname=' +
        //     post_data.uname +
        //     '&fid=' +
        //     post_data.food_data.fid +
        //     '&fprice=' +
        //     post_data.food_data.fprice +
        //     '&fprice_vip=' +
        //     post_data.food_data.fprice_vip +
        //     '&fprice_pickup=' +
        //     post_data.food_data.fprice_pickup +
        //     '&is_vip=' +
        //     post_data.is_vip +
        //     '&fcount=' +
        //     post_data.food_data.fcount +
        //     '&type=' +
        //     (isAdd ? 'add' : 'set') +
        //     '&feature_name=' +
        //     encodeURIComponent(post_data.extra_data.feature_name) +
        //     '&addon_names=' +
        //     encodeURIComponent(post_data.extra_data.addon_names) +
        //     '&addon_counts=' +
        //     encodeURIComponent(post_data.extra_data.addon_counts) +
        //     '&addon_pickup_discounts=' +
        //     encodeURIComponent(post_data.extra_data.addon_pickup_discounts) +
        //     '&addon_prices=' +
        //     post_data.extra_data.addon_prices +
        //     '&note=' +
        //     encodeURIComponent(post_data.extra_data.note)

        // fetch(aj_host + '/weapp/takeout/sharedcart/edit?' + params, {
        //     method: 'GET',
        // })
        //     .then((res) => {
        //         return res.text()
        //     })
        //     .then((res) => {
        //         res = JSON.parse(res)
        //         if (res.data.code === 100000) {
        //             if (post_data.type == 'add') {
        //                 commit('APP_SET_MSG', {
        //                     msg: 'add "' + post_data.food_data.fname + '" successfully',
        //                 })
        //                 commit('APP_NEW_ADD_FOOD', post_data.food_data)
        //             } else if (post_data.type == 'set') {
        //                 commit('APP_SET_MSG', {
        //                     msg: 'add "' + post_data.food_data.fname + '" successfully',
        //                 })
        //                 commit('APP_NEW_SET_FOOD', post_data.food_data)
        //             } else {
        //                 commit('APP_DEL_FOOD_INTO_CART', post_data.food_data)
        //                 commit('APP_SET_MSG', {
        //                     msg: 'remove "' + post_data.food_data.fname + '" successfully',
        //                 })
        //             }
        //         } else if (res.data.code === 100010) {
        //             // 共享购物车已关闭
        //             commit('APP_SHARED_CART_CLOSED', {
        //                 router: post_data.router,
        //                 sid: post_data.sid,
        //             })
        //         } else {
        //             commit('APP_SET_MSG', {
        //                 msg: 'set "' + res.data.msg + '" successfully',
        //             })
        //         }
        //     })
    },
    submit_cart({ commit, rootState, rootGetters, state }, post_data) {
        let aj_host = rootGetters.serverRoot
        if (isValidStr(state.gid)) {
            post_data.gid = state.gid
        }

        commit('APP_SET_IF_LOADING', { is_loading: true })

        // fetchApi(
        //     { commit },
        //     {
        //         request_name: 'submit_order',
        //         params: post_data,
        //         aj_host
        //     }
        // )
        //     .then((res) => {
        //         commit('APP_SET_IF_LOADING', { is_loading: false });
        //         commit('APP_CLEAR_CART_DATA', {});
        //         commit('APP_SET_MSG', {
        //             msg: 'Submit successfully'
        //         });
        //         commit('APP_SET_SHARED_OID', {
        //             shared_oid: res.data.data
        //         });

        //         commit('APP_SHARED_CART_CLOSED', {});
        //         setTimeout(function () {
        //             window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
        //         }, 100);
        //     })
        //     .catch(() => {
        //         commit('APP_SET_IF_LOADING', { is_loading: false });
        //     });
    },
    submit_cart_paypal({ commit, rootState, rootGetters, state }, post_data) {
        let aj_host = rootGetters.serverRoot
        commit('APP_SET_IF_LOADING', { is_loading: true })
        if (isValidStr(state.gid)) {
            post_data.gid = state.gid
        }
        // fetchApi(
        //     { commit },
        //     {
        //         request_name: 'submit_order_by_paypal',
        //         params: post_data,
        //         aj_host
        //     }
        // )
        //     .then((res) => {
        //         commit('APP_SET_IF_LOADING', { is_loading: false });
        //         commit('APP_CLEAR_CART_DATA', {});
        //         commit('APP_SET_MSG', {
        //             msg: 'Submit successfully'
        //         });
        //         commit('APP_SET_SHARED_OID', {
        //             shared_oid: res.data.data
        //         });

        //         commit('APP_SHARED_CART_CLOSED', {});
        //         setTimeout(function () {
        //             window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
        //         }, 100);
        //     })
        //     .catch(() => {
        //         commit('APP_SET_IF_LOADING', { is_loading: false });
        //     });
    },
    submit_cart_stripe({ commit, rootState, rootGetters, state }, post_data) {
        let aj_host = rootGetters.serverRoot
        commit('APP_SET_IF_LOADING', { is_loading: true })
        if (isValidStr(state.gid)) {
            post_data.gid = state.gid
        }
        // fetchApi(
        //     { commit },
        //     {
        //         request_name: 'submit_order_by_stripe',
        //         params: post_data,
        //         aj_host
        //     }
        // )
        //     .then((res) => {
        //         commit('APP_SET_IF_LOADING', { is_loading: false });
        //         commit('APP_CLEAR_CART_DATA', {});
        //         commit('APP_SET_MSG', {
        //             msg: 'Submit successfully'
        //         });
        //         commit('APP_SET_SHARED_OID', {
        //             shared_oid: res.data.data
        //         });

        //         commit('APP_SHARED_CART_CLOSED', {});
        //         setTimeout(function () {
        //             window.location.replace('/recepit?sid=' + post_data.sid + '&tid=' + post_data.tid);
        //         }, 100);
        //     })
        //     .catch(() => {
        //         commit('APP_SET_IF_LOADING', { is_loading: false });
        //     });
    },
    doToggleDetail({ commit, rootState }, isSHow) {
        commit('APP_TOGGLE_CART_DETAIL', isSHow)
    },
    init_paymethod({ commit, rootState, rootGetters }) {
        let aj_host = rootGetters.serverRoot
        fetch(aj_host + '/weapp/takeout/paymethod/get?sid=' + rootState.app.sid, {
            method: 'GET',
        })
            .then((res) => {
                return res.text()
            })
            .then((res) => {
                res = JSON.parse(res)
                if (res.data.code === 100000) {
                    commit('APP_INIT_PAYMETHOD', {
                        pay_method_setting: res.data.data.pay_method,
                        tip_method_setting: res.data.data.tip_method,
                        merchant_custom_value: res.data.data.merchant_custom_value,
                        choose_time: res.data.data.choose_time,
                        choose_time_ahead_days: res.data.data.choose_time_ahead_days,
                        choose_time_options: res.data.data.choose_time_options,
                    })
                }
            })
    },
    do_check({ commit, rootState, rootGetters }, router) {
        let aj_host = rootGetters.serverRoot
        fetch(aj_host + '/weapp/takeout/store/check?sid=' + rootState.app.sid + '&tid=' + rootState.app.shared_tid, {
            method: 'GET',
        })
            .then((res) => {
                return res.text()
            })
            .then((res) => {
                res = JSON.parse(res)
                if (res.data.code === 100000) {
                    let _enable = res.data.data.enable
                    let _err_msg = res.data.data.err_msg

                    commit('APP_INIT_TAKEOUT_ENABLE', {
                        is_open: _enable,
                        err_msg: _err_msg,
                    })

                    if (router.history.current.path !== '/welcome' && _enable == 0) {
                        commit('APP_SET_MSG', {
                            msg: _err_msg,
                        })

                        commit('APP_SHARED_CART_CLOSED', {
                            router: router,
                            sid: rootState.app.sid,
                        })
                    }
                }
            })
    },
    check_new({ commit, rootState }, tel) {
        let aj_host = rootState.debug ? 'https://takeout.minitable.link' : ''
        fetch(aj_host + '/weapp/takeout/user/check_new?sid=' + rootState.app.sid + '&tel=' + encodeURIComponent(tel), {
            method: 'GET',
        })
            .then((res) => {
                return res.text()
            })
            .then((res) => {
                res = JSON.parse(res)
                if (res.data.code === 100000) {
                    let _is_new = res.data.data.is_new || false

                    commit('APP_SET_USER_IS_NEW', {
                        is_new: _is_new,
                    })
                }
            })
    },
    check_vip({ commit, rootState, rootGetters }, tel) {
        let aj_host = rootGetters.serverRoot
        fetch(aj_host + '/weapp/takeout/user/check_vip?sid=' + rootState.app.sid + '&tel=' + encodeURIComponent(tel), {
            method: 'GET',
        })
            .then((res) => {
                return res.text()
            })
            .then((res) => {
                res = JSON.parse(res)
                if (res.data.code === 100000) {
                    let _is_vip = res.data.data.is_vip || false

                    commit('APP_SET_USER_IS_VIP', {
                        is_vip_new: _is_vip,
                    })
                }
            })
    },
    get_menu({ commit, rootState, rootGetters }, sid) {
        let aj_host = rootGetters.serverRoot
        fetch(aj_host + '/weapp/takeout/menu/get?sid=' + sid, {
            method: 'GET',
        })
            .then((res) => {
                return res.text()
            })
            .then((res) => {
                const res_data = JSON.parse(res).data
                if (!res_data || !res_data.data || res_data.data.menu_list.length < 1) {
                    return
                }
                const { first_menu, menu, menu_list, foods } = res_data.data
                let menu_id = first_menu
                if (!first_menu) {
                    menu_id = Object.keys(menu)[0]
                }

                if (res_data.code === 100000) {
                    commit('APP_INIT_MENU_DATA', {
                        show_food_list: menu[menu_id].list,
                        now_menu: menu_id,
                        menu_dict: menu,
                        menu_list: menu_list,
                        food_dict: foods,
                    })

                    commit('APP_UPDATE_CART_DATA', {
                        food_dict: res_data.data.foods,
                    })
                }
            })
    },
}

// mutations
const mutations = {
    SET_EID(state, _data) {
        // 保存eid,同时获取cart_data数据
        state.eid = _data.eid
        let cartData = state.cart_data_all_store[state.eid]
        if (cartData) {
            state.cart_data = cartData
        }
    },
    SET_LOADING(state, data) {
        state.loading = data
    },
    INIT_MENU_DATA(state, res_data) {
        state.show_food_list = res_data.show_food_list
        state.now_menu = res_data.now_menu
        state.menu_dict = res_data.menu_dict
        state.menu_list = res_data.menu_list
        state.food_dict = res_data.food_dict
        state.teamup = res_data.teamup
        state.store_info = res_data.store_info
        state.timezone = res_data.store_info.timezone || 'America/New_York'
        state.is_loading = false
    },
    APP_SET_PICKUP_ADDRESS_ACTIVE(state, _data) {
        state.pickupAddressActive = _data
    },
    APP_INIT_STORE_DATA(state, _data) {
        state.app_title = _data.sname
        state.sname = _data.sname
        _data.language = window.localStorage.getItem('language') || _data.language
        state.store_data = _data
    },
    APP_NEW_SET_FOOD(state, food_data) {
        let res = []
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list))
        let food_count = 0
        let plus_count = 0
        let total_price = 0
        let total_price_vip = 0
        let total_price_pickup = 0
        let total_price_savin = 0

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            let extra_price = 0
            let extra_price_pickup = 0
            if (_ori_food_list[i].addon_prices != '') {
                let _addon_items = _ori_food_list[i].addon_prices.split('@@')
                let _addon_counts = _ori_food_list[i].addon_counts.split('@@')
                let _addon_pickup_discounts = _ori_food_list[i].addon_pickup_discounts.split('@@')
                for (let m = 0, mLen = _addon_items.length; m < mLen; m++) {
                    let _addon_prices = _addon_items[m].split('|')
                    let _counts_arr = _addon_counts[m].split('|')
                    let _pickup_discounts = _addon_pickup_discounts[m].split('|')

                    for (let j = 0, jLen = _addon_prices.length; j < jLen; j++) {
                        extra_price = parseFloat(extra_price) + parseFloat(_addon_prices[j] * _counts_arr[j])
                        extra_price_pickup = parseFloat(extra_price_pickup) + parseFloat(_addon_prices[j] * _pickup_discounts[j] * _counts_arr[j])
                    }
                }
            }

            let _price = _ori_food_list[i].teamup_price

            if (food_data.fid == _ori_food_list[i].fid) {
                if (
                    _ori_food_list[i].feature_name == food_data.feature_name &&
                    _ori_food_list[i].uname == food_data.uname &&
                    _ori_food_list[i].note == food_data.note &&
                    _ori_food_list[i].addon_names == food_data.addon_names
                ) {
                    plus_count = food_data.set_count - _ori_food_list[i].fcount

                    _ori_food_list[i].fcount = food_data.set_count
                    if (_ori_food_list[i].fcount > 0) {
                        res.push(_ori_food_list[i])
                        total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].teamup_price) + extra_price)
                        if (_ori_food_list[i].fprice != 0) {
                            total_price_savin += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) - parseFloat(_ori_food_list[i].teamup_price))
                        }
                    }
                } else {
                    res.push(_ori_food_list[i])
                    total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].teamup_price) + extra_price)
                    if (_ori_food_list[i].fprice != 0) {
                        total_price_savin += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) - parseFloat(_ori_food_list[i].teamup_price))
                    }
                }
                food_count = food_count + _ori_food_list[i].fcount
            } else {
                res.push(_ori_food_list[i])
                food_count = food_count + _ori_food_list[i].fcount
                total_price += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].teamup_price) + extra_price)
                if (_ori_food_list[i].fprice != 0) {
                    total_price_savin += _ori_food_list[i].fcount * (parseFloat(_ori_food_list[i].fprice) - parseFloat(_ori_food_list[i].teamup_price))
                }
            }
        }

        state.cart_data.cart_food_dict[food_data.fid].mix_fcount = state.cart_data.cart_food_dict[food_data.fid].mix_fcount + plus_count

        state.cart_data.total_food_count = food_count
        state.cart_data.cart_food_list = res
        state.cart_data.total_price = parseFloat(total_price).toFixed(2)
        state.cart_data.total_price_vip = parseFloat(total_price_vip).toFixed(2)
        state.cart_data.total_price_pickup = parseFloat(total_price_pickup).toFixed(2)
        state.cart_data.total_price_savin = parseFloat(total_price_savin).toFixed(2)
        state.cart_data.cart_food_dict.random = new Date().getTime()
        state.cart_data_all_store[state.eid] = state.cart_data
    },
    APP_NEW_ADD_FOOD(state, food_data) {
        let res = []
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list))
        let add_flag = false
        let food_count = 0

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            if (food_data.fid == _ori_food_list[i].fid) {
                if (
                    _ori_food_list[i].feature_name == food_data.feature_name &&
                    _ori_food_list[i].uname == food_data.uname &&
                    _ori_food_list[i].note == food_data.note &&
                    _ori_food_list[i].addon_names == food_data.addon_names
                ) {
                    _ori_food_list[i].fcount = _ori_food_list[i].fcount + food_data.add_count
                    res.push(_ori_food_list[i])
                } else {
                    res.push(_ori_food_list[i])
                    if (!add_flag) {
                        res.push(food_data)
                        food_count = food_count + food_data.add_count
                    }
                }
                add_flag = true
                food_count = food_count + _ori_food_list[i].fcount
            } else {
                res.push(_ori_food_list[i])
                food_count = food_count + _ori_food_list[i].fcount
            }
        }

        if (!add_flag) {
            res.push(food_data)
            food_count = food_count + food_data.add_count
        }

        if (!state.cart_data.cart_food_dict[food_data.fid]) {
            state.cart_data.cart_food_dict[food_data.fid] = {
                mix_fcount: food_data.add_count,
                teamup_price: food_data.teamup_price,
                price: food_data.fprice,
                price_vip: food_data.fprice_vip,
                price_pickup: food_data.fprice_pickup,
            }
        } else {
            state.cart_data.cart_food_dict[food_data.fid].mix_fcount = state.cart_data.cart_food_dict[food_data.fid].mix_fcount + food_data.add_count
        }

        let _price = food_data.teamup_price
        let _pickup_price = food_data.fprice_pickup ? food_data.fprice_pickup : food_data.fprice

        state.cart_data.total_food_count = food_count
        state.cart_data.cart_food_list = res
        state.cart_data.total_price = (parseFloat(state.cart_data.total_price) + (parseFloat(food_data.teamup_price) + parseFloat(food_data.addon_total_price)) * food_data.add_count).toFixed(2)
        if (food_data.fprice != 0) {
            state.cart_data.total_price_savin = (parseFloat(state.cart_data.total_price_savin) + (parseFloat(food_data.fprice) - parseFloat(food_data.teamup_price)) * food_data.add_count).toFixed(2)
        }
        // state.cart_data.total_price_vip = (parseFloat(state.cart_data.total_price_vip) + (parseFloat(_price) + parseFloat(food_data.addon_total_price)) * food_data.add_count).toFixed(2)
        // state.cart_data.total_price_pickup = (
        //     parseFloat(state.cart_data.total_price_pickup) +
        //     (parseFloat(_pickup_price) + parseFloat(food_data.addon_total_price_pickup)) * food_data.add_count
        // ).toFixed(2)
        state.cart_data.cart_food_dict.random = new Date().getTime()
        state.cart_data_all_store[state.eid] = state.cart_data
    },
    APP_DEL_FOOD_INTO_CART(state, food_data) {
        let now_count = food_data.fcount
        let res = []
        let _ori_food_list = JSON.parse(JSON.stringify(state.cart_data.cart_food_list))
        let food_count = 0

        for (let i = 0, len = _ori_food_list.length; i < len; i++) {
            if (food_data.fid == _ori_food_list[i].fid) {
                if (_ori_food_list[i].feature_default_value == food_data.default_value && _ori_food_list[i].uname == food_data.uname) {
                    if (now_count == 1) {
                        continue
                    } else {
                        food_count = food_count + food_data.fcount
                        res.push(food_data)
                    }
                } else {
                    food_count = food_count + _ori_food_list[i].fcount
                    res.push(_ori_food_list[i])
                }
            } else {
                food_count = food_count + _ori_food_list[i].fcount
                res.push(_ori_food_list[i])
            }
        }

        if (state.cart_data.cart_food_dict[food_data.fid]) {
            if (state.cart_data.cart_food_dict[food_data.fid].mix_fcount == 1) {
                state.cart_data.cart_food_dict[food_data.fid] = null
            } else {
                state.cart_data.cart_food_dict[food_data.fid].mix_fcount--
            }
        }

        let _price = food_data.teamup_price
        let _pickup_price = food_data.fprice_pickup ? food_data.fprice_pickup : food_data.fprice

        state.cart_data.cart_food_list = res
        state.cart_data.total_food_count = food_count
        state.cart_data.total_price = (parseFloat(state.cart_data.total_price) - parseFloat(food_data.fprice)).toFixed(2)
        if (food_data.fprice != 0) {
            state.cart_data.total_price_savin = (parseFloat(state.cart_data.total_price_savin) - (parseFloat(food_data.fprice) - parseFloat(food_data.teamup_price))).toFixed(2)
        }
        // state.cart_data.total_price_vip = (parseFloat(state.cart_data.total_price_vip) - parseFloat(_price)).toFixed(2)
        // state.cart_data.total_price_pickup = (parseFloat(state.cart_data.total_price_pickup) - parseFloat(_pickup_price)).toFixed(2)
        state.cart_data.cart_food_dict.random = new Date().getTime()
        state.cart_data_all_store[state.eid] = state.cart_data
    },
    APP_CLEAR_CART_DATA(state) {
        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime(),
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            total_price_pickup: '0.00',
            total_price_savin: '0.00',
            remark: '',
            user_count: state.cart_data.user_count,
            total_food_count: 0,
        }
        state.cart_data_all_store[state.eid] = state.cart_data
    },
    APP_SET_JOIN_TABLE_INFO(state, _data) {
        state.email = _data.email
        state.uname = _data.email
        state.shared_cart_id = _data.cartid
        state.shared_tid = _data.tid
    },
    APP_SET_SHARED_TIMER(state, _timer) {
        state.shared_foods_timer = _timer
    },
    APP_SHARED_CART_CLOSED(state, _data) {
        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime(),
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            total_price_pickup: '0.00',
            remark: '',
            user_count: 0,
            total_food_count: 0,
        }
        state.shared_oid = ''
        state.shared_tid = ''
        state.shared_tname = ''
        state.shared_cart_id = ''
        state.shared_table_pswd = ''
        state.gid = ''

        localStorage.removeItem('shared_tid')
        localStorage.removeItem('shared_tname')
        localStorage.removeItem('shared_cart_id')
        localStorage.removeItem('shared_oid')
        localStorage.removeItem('shared_table_pswd')
        localStorage.removeItem('gid')
    },
    APP_SET_SHARED_OID(state, _data) {
        state.shared_oid = _data.shared_oid
        state.has_new_order = true
        // window.localStorage.setItem('shared_oid', _data.shared_oid)
    },
    APP_SET_HAS_NEW_ORDER(state, _data) {
        state.has_new_order = true
    },
    APP_SET_MSG(state, _data) {
        state.msg = _data.msg
    },
    APP_SET_SID(state, { sid, gid }) {
        if (sid === localStorage.client_sid && gid === localStorage.gid) {
            return
        }

        if (isValidStr(gid)) {
            state.gid = gid
            localStorage.gid = gid
        } else {
            state.gid = ''
            localStorage.removeItem('gid')
        }
        if (isValidStr(sid)) {
            localStorage.client_sid = sid
            state.sid = sid
        }

        state.cart_data = {
            cart_food_list: [],
            cart_food_dict: {
                random: new Date().getTime(),
            },
            total_price: '0.00',
            total_price_vip: '0.00',
            total_price_pickup: '0.00',
            remark: '',
            user_count: 0,
            total_food_count: 0,
        }
        state.shared_oid = ''
        state.shared_tid = ''
        state.shared_tname = ''
        state.shared_cart_id = ''
        state.shared_table_pswd = ''

        window.localStorage.removeItem('shared_tid')
        window.localStorage.removeItem('shared_tname')
        window.localStorage.removeItem('shared_cart_id')
        window.localStorage.removeItem('shared_oid')
        window.localStorage.removeItem('shared_table_pswd')
    },
    APP_HAVE_READ_NEW_ORDER(state) {
        state.has_new_order = false
    },
    APP_TOGGLE_CART_DETAIL(state, show) {
        state.show_cart_detail = show
    },
    APP_TOGGLE_LEFT_NAV(state, _show) {
        state.show_left_nav = _show
    },
    APP_SET_APP_TITLE(state, _title) {
        state.app_title = _title
    },
    APP_SHOW_MENU_NAV(state, _show_menu_nav) {
        state.show_menu_nav = _show_menu_nav
    },
    APP_INIT_PAYMETHOD(state, _data) {
        state.pay_method_setting = _data.pay_method_setting
        state.tip_method_setting = _data.tip_method_setting
        state.merchant_custom_value = _data.merchant_custom_value
        state.choose_time = _data.choose_time
        state.choose_time_ahead_days = _data.choose_time_ahead_days
        state.choose_time_options = _data.choose_time_options
    },
    APP_INIT_TAKEOUT_ENABLE(state, _data) {
        state.is_open = _data.is_open
        state.err_msg = _data.err_msg
    },
    APP_SET_CHECK_TIMER(state, timer) {
        state.check_timer = timer
    },
    APP_SET_USER_IS_NEW(state, _data) {
        state.is_new = _data.is_new
    },
    APP_INIT_OTHER_SETTING(state, _data) {
        state.full_coupon = _data.full_coupon || []
        state.first_discount_amount = _data.first_discount_amount || 0
        state.first_discount_type = _data.first_discount_type || 0
        state.first_discount_limit_amount = _data.first_discount_limit_amount || 0
        // state.pickup_amount = _data.pickup_amount || 0;
        // state.pickup_enable = _data.pickup_enable || 0;
        state.annoucement = _data.annoucement || ''
        state.delivery_vip_free = _data.delivery_vip_free || 0
        // state.delivery_enable = _data.delivery_enable || 0;
        state.delivery_area_list = _data.delivery_area_list || []
        // state.pickup_discount_enable = _data.pickup_discount_enable || 0;

        state.feature_image = _data.feature_image
        state.joinin_back_color = _data.joinin_back_color
        state.notice_layer_title_color = _data.notice_layer_title_color
        state.notice_layer_subtitle = _data.notice_layer_subtitle
        state.notice_layer_ps_content = _data.notice_layer_ps_content

        state.pickup_setting = _data.pickup_setting
        state.delivery_setting = _data.delivery_setting
        state.dinein_setting = _data.dinein_setting && JSON.parse(_data.dinein_setting)
        state.recommend_popup_setting = _data.recommend_popup_setting
    },
    APP_SET_USER_IS_VIP(state, _data) {
        state.is_vip_new = _data.is_vip_new
    },
    APP_SET_PHONE_NUM(state, _data) {
        state.phone_num = _data.phone_num
        state.code = _data.code
    },
    APP_INIT_MENU_DATA(state, res_data) {
        state.show_food_list = res_data.show_food_list
        state.now_menu = res_data.now_menu
        state.menu_dict = res_data.menu_dict
        state.menu_list = res_data.menu_list
        state.food_dict = res_data.food_dict
        state.is_loading_menu = false
    },
    APP_SET_MENU_TIMER(state, timer) {
        state.menu_timer = timer
    },
    APP_UPDATE_CART_DATA(state, _data) {
        let ori_cart_data = JSON.parse(JSON.stringify(state.cart_data))
        let { cart_food_list, cart_food_dict, total_price, total_price_vip, total_food_count } = ori_cart_data
        let food_dict = _data.food_dict

        let new_list = []
        let changed = false
        for (let i = 0; i < cart_food_list.length; i++) {
            let temp_data = JSON.parse(JSON.stringify(cart_food_list[i]))
            let { fid, fprice, addon_names, addon_prices, addon_counts, fprice_pickup, addon_pickup_discounts, fcount } = temp_data

            if (!food_dict[fid] || food_dict[fid].is_out_of_stock == 1) {
                changed = true
                continue
            }

            if (addon_names) {
                let ori_addon_names_arr = addon_names.split('@@')
                let ori_addon_prices_arr = addon_prices.split('@@')
                let ori_addon_counts_arr = addon_counts.split('@@')
                let ori_addon_pickup_discounts_arr = addon_pickup_discounts.split('@@')

                let now_addon_list = food_dict[fid].addon_data
                let now_addon_dict = {}
                for (let j = 0; j < now_addon_list.length; j++) {
                    let { addon_values_name, addon_values_name_chn, addon_values_price, addon_values_availability, pickup_discount } = now_addon_list[j]

                    let addon_values_name_arr = addon_values_name.split('|')
                    let addon_values_name_chn_arr = addon_values_name_chn.split('|')
                    let addon_values_price_arr = addon_values_price.split('|')
                    let addon_values_availability_arr = addon_values_availability.split('|')

                    for (let m = 0; m < addon_values_name_arr.length; m++) {
                        now_addon_dict[addon_values_name_arr[m]] = {
                            price: addon_values_price_arr[m] || 0,
                            pickup_discount: pickup_discount,
                            availability: addon_values_availability_arr[m] == 1 ? true : false,
                        }
                        now_addon_dict[addon_values_name_chn_arr[m]] = {
                            price: addon_values_price_arr[m] || 0,
                            pickup_discount: pickup_discount,
                            availability: addon_values_availability_arr[m] == 1 ? true : false,
                        }
                    }
                }

                let new_addon_name = []
                let new_addon_price = []
                let new_addon_count = []
                let new_addon_pickup_discount = []
                let new_addon_total_price = 0
                let new_addon_total_price_pickup = 0

                for (let n = 0; n < ori_addon_names_arr.length; n++) {
                    let temp_name = ori_addon_names_arr[n]
                    let temp_price = ori_addon_prices_arr[n]
                    let temp_count = ori_addon_counts_arr[n]
                    let temp_pickup_discounts = ori_addon_pickup_discounts_arr[n]

                    let item_temp_names = temp_name.split('|')
                    let item_temp_counts = temp_count.split('|')
                    let item_temp_pickup_discounts = temp_pickup_discounts.split('|')

                    let child_addon_price_arr = []
                    let child_addon_name_arr = []
                    let child_addon_count_arr = []
                    let child_addon_pickup_discounts_arr = []

                    for (let l = 0, jLen = item_temp_names.length; l < jLen; l++) {
                        let child_addon_name = item_temp_names[l]
                        let child_addon_count = item_temp_counts[l]
                        let child_addon_pickup_discount = item_temp_pickup_discounts[l]

                        if (!now_addon_dict[child_addon_name] || !now_addon_dict[child_addon_name].availability) {
                            changed = true
                            continue
                        }

                        child_addon_name_arr.push(child_addon_name)
                        child_addon_count_arr.push(child_addon_count)

                        let now_pickup_discount = 1
                        if (child_addon_pickup_discount != now_addon_dict[child_addon_name].pickup_discount) {
                            changed = true
                            child_addon_pickup_discounts_arr.push(now_addon_dict[child_addon_name].pickup_discount)
                            now_pickup_discount = now_addon_dict[child_addon_name].pickup_discount
                        } else {
                            child_addon_pickup_discounts_arr.push(child_addon_pickup_discount)
                            now_pickup_discount = child_addon_pickup_discount
                        }

                        if (temp_price != now_addon_dict[child_addon_name].price) {
                            changed = true
                            child_addon_price_arr.push(now_addon_dict[child_addon_name].price)
                            new_addon_total_price = new_addon_total_price + now_addon_dict[child_addon_name].price * child_addon_count
                            new_addon_total_price_pickup = new_addon_total_price + now_addon_dict[child_addon_name].price * child_addon_count * now_pickup_discount
                        } else {
                            child_addon_price_arr.push(temp_price)
                            new_addon_total_price = new_addon_total_price + temp_price * child_addon_count
                            new_addon_total_price_pickup = new_addon_total_price + temp_price * child_addon_count * now_pickup_discount
                        }
                    }

                    new_addon_name.push(child_addon_name_arr.join('|'))
                    new_addon_price.push(child_addon_price_arr.join('|'))
                    new_addon_count.push(child_addon_count_arr.join('|'))
                    new_addon_pickup_discount.push(child_addon_pickup_discounts_arr.join('|'))
                }

                temp_data.addon_total_price = new_addon_total_price.toFixed(2)
                temp_data.addon_names = new_addon_name.join('@@')
                temp_data.addon_prices = new_addon_price.join('@@')
                temp_data.addon_counts = new_addon_count.join('@@')
                temp_data.addon_pickup_discounts = new_addon_pickup_discount.join('@@')
            }

            if (fprice != food_dict[fid].fprice) {
                temp_data.fprice = food_dict[fid].fprice
                changed = true
            }

            if (fprice_pickup != food_dict[fid].fprice_pickup) {
                temp_data.fprice_pickup = food_dict[fid].fprice_pickup
                changed = true
            }

            if (food_dict[fid].once_limit > 0 && fcount > food_dict[fid].once_limit) {
                temp_data.fcount = food_dict[fid].once_limit
                changed = true
            }

            new_list.push(temp_data)
        }

        let new_cart_dict = {}
        for (let _fid in cart_food_dict) {
            let temp_data = JSON.parse(JSON.stringify(cart_food_dict[_fid]))

            if (_fid != 'random') {
                if (!food_dict[_fid] || food_dict[_fid].is_out_of_stock == 1) {
                    changed = true
                    continue
                }

                if (temp_data.price != food_dict[_fid].fprice) {
                    temp_data.price = food_dict[_fid].fprice
                    changed = true
                }

                if (temp_data.price_vip != food_dict[_fid].fprice_vip) {
                    temp_data.price_vip = food_dict[_fid].fprice_vip
                    changed = true
                }

                if (temp_data.price_pickup != food_dict[_fid].fprice_pickup) {
                    temp_data.price_pickup = food_dict[_fid].fprice_pickup
                    changed = true
                }
                new_cart_dict[_fid] = temp_data
            } else {
                new_cart_dict.random = new Date().getTime()
            }
        }

        if (changed) {
            let food_count = 0
            let total_price = 0
            let total_price_vip = 0
            let total_price_pickup = 0

            for (let k = 0; k < new_list.length; k++) {
                let temp_food_data = new_list[k]
                let extra_price = 0
                let extra_price_pickup = 0
                if (temp_food_data.addon_prices != '') {
                    let _addon_items = temp_food_data.addon_prices.split('@@')
                    let _addon_counts = temp_food_data.addon_counts.split('@@')
                    let _addon_pickup_discounts = temp_food_data.addon_pickup_discounts.split('@@')
                    for (let m = 0, mLen = _addon_items.length; m < mLen; m++) {
                        let _addon_prices = _addon_items[m].split('|')
                        let _counts_arr = _addon_counts[m].split('|')
                        let _pickup_discounts_arr = _addon_pickup_discounts[m].split('|')
                        for (let j = 0, jLen = _addon_prices.length; j < jLen; j++) {
                            extra_price = parseFloat(extra_price) + parseFloat(_addon_prices[j] * _counts_arr[j])
                            extra_price_pickup = parseFloat(extra_price_pickup) + parseFloat(_addon_prices[j] * _counts_arr[j] * _pickup_discounts_arr[j])
                        }
                    }
                }

                let _price = temp_food_data.is_vip && temp_food_data.fprice_vip ? temp_food_data.fprice_vip : temp_food_data.fprice
                let _pickup_price = temp_food_data.fprice_pickup ? temp_food_data.fprice_pickup : temp_food_data.fprice

                food_count = food_count + temp_food_data.fcount
                total_price += temp_food_data.fcount * (parseFloat(temp_food_data.fprice) + extra_price)
                total_price_vip += temp_food_data.fcount * (parseFloat(_price) + extra_price)
                total_price_pickup += temp_food_data.fcount * (parseFloat(_pickup_price) + extra_price_pickup)
            }

            state.cart_data.cart_food_list = new_list
            state.cart_data.total_food_count = food_count
            state.cart_data.total_price = total_price.toFixed(2)
            state.cart_data.total_price_vip = total_price_vip.toFixed(2)
            state.cart_data.total_price_pickup = total_price_pickup.toFixed(2)
            state.cart_data.cart_food_dict = new_cart_dict
            state.cart_data_all_store[state.eid] = state.cart_data
        }
    },
    APP_SET_UNAME(state, _data) {
        state.uname = _data.name
        window.localStorage.setItem('uname', _data.name)
    },
    APP_SET_IF_LOADING(state, _data) {
        state.is_loading = _data.is_loading
    },
    APP_SET_ORDER_TYPE(state, _data) {
        state.type = _data.type
    },
    APP_SET_ADDRESS(state, _data) {
        state.state = _data.state
        state.city = _data.city
        state.street_address1 = _data.street_address1
        state.street_address2 = _data.street_address2
        state.zip_code = _data.zip_code
        state.zip_code_index = _data.zip_code_index
    },
    APP_SET_LANGUAGE(state, _data) {
        let value = _data.language
        if (value != 'chn' && value != 'eng' && value != 'kor') {
            value = 'eng'
        }
        state.language = value
        window.localStorage.setItem('language', value)
    },
    APP_SET_CODE(state, payload) {
        state.code = payload
    },
    APP_SET_DineInTable(state, payload) {
        state.dineInTable = payload
    },
    APP_SET_MULTI_LANG(state, data) {
        const map = {}
        data.forEach((item) => {
            const business = item.business

            if (!map[business]) {
                map[business] = {}
            }
            map[business][item.eng] = item
        })
        state.multi_lang = map
    },
}

function _checkAdd(food_data, rootState) {
    let cart_food_list = rootState.app.cart_data.cart_food_list || []
    let isAdd = true

    for (let i = 0; i < cart_food_list.length; i++) {
        if (food_data.fid == cart_food_list[i].fid) {
            if (
                cart_food_list[i].feature_name == food_data.feature_name &&
                cart_food_list[i].uname == food_data.uname &&
                cart_food_list[i].note == food_data.note &&
                cart_food_list[i].addon_names == food_data.addon_names
            ) {
                food_data.fcount = cart_food_list[i].fcount + food_data.add_count
                isAdd = false
            }
        }
    }

    return {
        isAdd,
        food_data,
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
