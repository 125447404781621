<template>
    <div class="container" v-if="order.charge_detail">
        <div class="header">
            <div class="cart-icon">
                <!-- <span class="iconfont icon-cart"></span> -->
            </div>
            <div class="title">{{ $t('review.review') }}</div>
            <div class="card-icon">
                <!-- <span class="iconfont icon-card-o"></span> -->
            </div>
        </div>
        <notice-bar class="broadcast" :text="trans(radioStr, 'str')" />
        <div class="column success">
            <div class="icon"><span class="iconfont icon-success"></span></div>
            <div class="row text">{{ $t('review.orderSubmitted') }}</div>
            <div class="row text">{{ $t('review.Congratulations') }} ${{ order.charge_detail.discount > 0 ? order.charge_detail.discount : 0 }}.</div>
            <div class="row save-more">{{ $t('review.saveMore') }}</div>
            <div class="row btns">
                <div class="share-btn btn-left" @click="gotoMenu"><span class="iconfont icon-cart"></span>{{ $t('review.oneMore') }}</div>
                <div id="share" class="share-btn" @click="share" :data-clipboard-text="copyUrl"><span class="iconfont icon-share"></span>{{ $t('review.share') }}</div>
            </div>
            <div class="row save-more">{{ $t('review.morePeople') }}</div>
            <div class="end">
                <span class="iconfont icon-hourglass"></span>
                <span>{{ $t('common.salesEnd') }}</span>
                <span class="countdown"><count-down class="van-countdown" :time="teamup.duration" format="DD day(s): HH:mm:ss"></count-down></span>
            </div>
        </div>
        <div :class="['process-bar', teamup.event_status >= 401 ? 'green' : '']">
            <div :class="['item item-first', teamup.event_status >= 200 ? 'active' : '']">
                <div class="process-text first">{{ $t('review.step1') }}</div>
            </div>
            <div :class="['item', teamup.event_status >= 300 ? 'active' : '']">
                <div class="triangle" v-if="teamup.event_status >= 200"></div>
                <div class="arrow" v-if="teamup.event_status != 200"></div>
                <div class="process-text">{{ $t('review.step2') }}</div>
            </div>
            <div :class="['item', teamup.event_status >= 400 ? 'active' : '']">
                <div class="triangle" v-if="teamup.event_status >= 300"></div>
                <div class="arrow" v-if="teamup.event_status != 300"></div>
                <div class="process-text">{{ $t('review.step3') }}</div>
            </div>
            <div :class="['item', teamup.event_status >= 401 ? 'active' : '']">
                <div class="triangle" v-if="teamup.event_status >= 400"></div>
                <div class="arrow" v-if="teamup.event_status != 400"></div>
                <div class="process-text" v-if="teamup.delivery_method != 'pickup' && language == 'eng'">
                    {{ $t('review.step4') }} {{ $moment(Number(teamup.teamup_time)).tz(timezone).format('ddd, MM/DD') }}
                </div>
                <div class="process-text" v-if="teamup.delivery_method == 'pickup' && language == 'eng'">
                    {{ $t('review.stepPickup4') }} {{ $moment(Number(teamup.teamup_time)).tz(timezone).format('ddd, MM/DD') }}
                </div>
                <div class="process-text" v-if="teamup.delivery_method != 'pickup' && language == 'chn'">
                    于 {{ $moment(Number(teamup.teamup_time)).tz(timezone).format('ddd, MM/DD') }} {{ $t('review.step4') }}
                </div>
                <div class="process-text" v-if="teamup.delivery_method == 'pickup' && language == 'chn'">
                    于 {{ $moment(Number(teamup.teamup_time)).tz(timezone).format('ddd, MM/DD') }} {{ $t('review.stepPickup4') }}
                </div>
            </div>
        </div>
        <div class="column gery">
            <div class="title">{{ $t('review.orderInfo') }}</div>
            <div class="info">
                <div class="label" v-if="language == 'eng'">
                    Time to <br />
                    Get your Items
                </div>
                <div class="label" v-else>{{ $t('common.timeToGet') }}</div>
                <div class="content">
                    <span class="text">{{ $moment(Number(teamup.teamup_time)).tz(timezone).format('ddd, MMM DD, YYYY H:mm A') }} - {{ TimezoneCode[timezone] }}</span>
                </div>
            </div>
            <div class="info" v-if="teamup.delivery_method == 'pickup'">
                <div class="label">{{ $t('review.pickup') }}</div>
                <div class="content">
                    <div class="text">{{ order.name }}</div>
                    <div class="text text-samll">{{ order.phone_number }}</div>
                    <div class="text text-samll">{{ order.address_detail.pickup_name }} {{ order.address_detail.pickup_address }}</div>
                </div>
            </div>
            <div class="info" v-else>
                <div class="label">{{ $t('common.doorstepDelivery') }}</div>
                <div class="content">
                    <div class="text">{{ order.name }}</div>
                    <div class="text text-samll">{{ order.phone_number }}</div>
                    <div class="text text-samll">
                        {{ order.address_detail.street_address1 }} {{ order.address_detail.street_address2 }}, {{ order.address_detail.city }}, {{ order.address_detail.state }}
                        {{ order.address_detail.zip_code }}
                    </div>
                </div>
            </div>

            <div class="info">
                <div class="label">{{ $t('review.orderTotal') }}</div>
                <div class="content">
                    <span class="text">${{ order.charge_detail.price }}</span>
                </div>
            </div>
            <div class="info">
                <div class="label">{{ $t('review.note') }}</div>
                <div class="content">
                    <span class="text text-samll">{{ order.note }}</span>
                </div>
            </div>
            <div class="info">
                <div class="label">{{ $t('review.orderId') }}</div>
                <div class="content">
                    <span class="text text-samll">{{ order.order_id }}</span>
                </div>
            </div>
        </div>
        <div class="column" style="margin-top: 26px">
            <div class="title">{{ $t('checkout.orderDetails') }}</div>
            <div class="order">
                <div class="item" v-for="(item, index) in order.item_detail" :key="item.fid + index">
                    <div class="food">
                        <div class="num">{{ item.count }} X</div>
                        <div class="name">{{ translate('item', item.fname) }}</div>
                        <div class="remark" v-if="item.ofeature_name">({{ item.ofeature_name }})</div>
                        <div class="price">${{ (item.count * item.teamup_price).toFixed(2) }}</div>
                    </div>
                    <!-- <div class="addon" v-if="item.addon_names">
                        <div class="addon-item" v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')" :key="addon_index">
                            <span v-if="addon_name_item">{{ item.addon_counts.split('@@')[addon_index] }} X {{ addon_name_item }}</span>
                            (${{ Number(item.addon_prices.split('@@')[addon_index]).toFixed(2) }})
                        </div>
                    </div> -->
                    <template v-if="item.addon_names">
                        <template v-for="(addon_name_item, addon_index) in item.addon_names.split('@@')" v-if="item.addon_names">
                            <div class="addon" v-for="(item_name, item_index) in addon_name_item.split('|')" v-bind:key="item_name + '_item_val'">
                                <span v-if="addon_name_item">{{ item.addon_counts.split('@@')[addon_index].split('|')[item_index] }} X {{ translate('addon_value', item_name) }}</span>
                                (${{ (item.addon_counts.split('@@')[addon_index].split('|')[item_index] * item.addon_prices.split('@@')[addon_index].split('|')[item_index]).toFixed(2) }})
                            </div>
                        </template>
                    </template>
                    <div class="note" v-if="item.note">{{ $t('review.note') }}: {{ item.note }}</div>
                </div>
            </div>
            <div class="total">
                <div class="row row-line">
                    <div class="label">{{ $t('common.subtotal') }}</div>
                    <div class="content savin">{{ $t('common.savin') }} $ {{ order.charge_detail.discount > 0 ? order.charge_detail.discount.toFixed(2) : 0 }}</div>
                    <div class="content no-right">
                        $ {{ (order.charge_detail.price - 1 * order.charge_detail.tax - 1 * order.charge_detail.tip - 1 * order.charge_detail.delivery_fee).toFixed(2) }}
                    </div>
                </div>
                <div class="row">
                    <div class="label">{{ $t('common.tax') }}</div>
                    <div class="content">$ {{ order.charge_detail.tax }}</div>
                </div>
                <div class="row" v-if="teamup.delivery_method == 'delivery'">
                    <div class="label">{{ $t('common.deliveryFee') }}</div>
                    <div class="content">$ {{ order.charge_detail.delivery_fee }}</div>
                </div>
                <div class="row">
                    <div class="label">{{ $t('cart.tip') }}</div>
                    <div class="content">$ {{ order.charge_detail.tip }}</div>
                </div>
                <div class="row" style="font-weight: 700">
                    <div class="label">{{ $t('common.total') }}</div>
                    <div class="content">$ {{ order.charge_detail.price }}</div>
                </div>
            </div>
        </div>
        <div class="column store-column">
            <div class="store">
                <div class="logo">
                    <img class="fullimg" :src="store_info.slogo" alt="" />
                </div>
                <div class="store-name">{{ store_info.sname }}</div>
            </div>
            <div class="info">
                <div class="label">{{ $t('checkout.phone') }}</div>
                <div class="content">
                    <span class="text text-samll">{{ store_info.stel }}</span>
                </div>
            </div>
            <div class="info">
                <div class="label">Email</div>
                <div class="content">
                    <span class="text text-samll">{{ store_info.semail }}</span>
                </div>
            </div>
            <div class="info">
                <div class="label">{{ $t('common.address') }}</div>
                <div class="content">
                    <span class="text text-samll">{{ store_info.street_address }}, {{ store_info.locality }}, {{ store_info.postal_code }} {{ store_info.country }}</span>
                </div>
            </div>
            <div class="other" v-if="otherOrders.length > 0">
                <div class="title">{{ $t('common.otherOrders') }}</div>
                <div class="order" v-for="(item, index) in otherOrders" :key="index">
                    <div class="serial">
                        <div class="num">#{{ item.id }}</div>
                        <div class="name">{{ item.name }}</div>
                    </div>
                    <div class="food" v-for="(food, fIndex) in item.foods" :key="fIndex">
                        {{ translate('item', food.fname) }}{{ food.ofeature_name ? `(${translate('feature_value', food.ofeature_name)})` : '' }} X {{ food.count }}
                    </div>
                    <div class="border"></div>
                </div>
            </div>
        </div>
        <div class="footer">
            <span class="powered">powered by </span>
            <span class="logo">
                Minitable
                <div class="red-cube"></div>
            </span>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { NoticeBar, CountDown } from 'vant'
import Clipboard from 'clipboard'
import { TimezoneCode } from '@/utils/constants'

export default {
    name: 'review',
    data() {
        return {
            TimezoneCode,
            teamup: {},
            store_info: {},
            order: {},
            shared_oid: '',
            radioStr: '',
            otherOrders: [],
        }
    },
    components: {
        NoticeBar,
        CountDown,
    },
    created() {},
    mounted() {
        this.set_loading(true)
        let { oid } = this.$route.params
        this.shared_oid = oid
        if (!this.shared_oid) {
            this.$router.push({ path: '/menu/' + this.teamup.eid })
        }
        this.queryOrder({ oid: this.shared_oid }).then((res) => {
            this.store_info = res.data.store
            if (this.store_info.teamup_enable == 0) {
                this.$router.push({ path: '/welcome/1' })
            }
            let teamup = res.data.teamup
            let now = this.$moment()
            let endTime = this.$moment(Number(teamup.end_time))
            teamup.duration = endTime.diff(now)
            this.teamup = teamup

            let order = res.data.order
            order.charge_detail = JSON.parse(order.charge_detail)
            order.address_detail = JSON.parse(order.address_detail)
            order.item_detail = JSON.parse(order.item_detail)
            this.order = order
            this.set_loading(false)
            this.queryOtherOrder()
        })
    },
    computed: {
        ...mapState(['debug']),
        ...mapGetters({
            loading: 'app/getLoading',
            language: 'app/get_language',
            timezone: 'app/get_timezone',
            translate: 'app/translate',
            // shared_oid: 'app/get_shared_oid',
            // teamup: 'app/get_teamup',
            // store_info: 'app/get_store_info',
        }),
        copyUrl() {
            if (this.debug) {
                return window.location.host + '/dist/#/menu/' + this.teamup.eid
            }
            return window.location.host + '/#/menu/' + this.teamup.eid
        },
    },
    methods: {
        ...mapActions({
            queryOrder: 'order/queryOrder',
            queryOrderShow: 'order/queryOrderShow',
        }),
        ...mapMutations({
            set_msg: 'app/APP_SET_MSG',
            set_loading: 'app/SET_LOADING',
        }),
        gotoMenu() {
            this.$router.push({ path: '/menu/' + this.teamup.eid })
        },
        share() {
            const clipboard = new Clipboard('#share')
            clipboard.on('success', (e) => {
                this.set_msg({ msg: this.$t('review.copySuccess') })
                // 释放内存
                clipboard.destroy()
            })
            clipboard.on('error', (e) => {
                // 不支持复制
                this.set_msg({ msg: this.$t('review.copyFail') })
                // 释放内存
                clipboard.destroy()
            })
        },

        queryOtherOrder() {
            this.queryOrderShow({ eid: this.teamup.eid, phone: this.order.phone_number }).then((res) => {
                let orderShow = res.data.order
                let radioStr = ''
                let radioStr_chn = ''
                let radioStr_kor = ''
                let otherOrders = []
                for (let i = 0; i < orderShow.length; i++) {
                    const item = orderShow[i]
                    let foodStr = ''
                    let foodStr_chn = ''
                    let foodStr_kor = ''
                    let foods = JSON.parse(item.item_detail)
                    foods.forEach((e) => {
                        foodStr += `${this.translate('item', e.fname, 'eng')} X ${e.count}, `
                        foodStr_chn += `${this.translate('item', e.fname, 'chn')} X ${e.count}, `
                        foodStr_kor += `${this.translate('item', e.fname, 'kor')} X ${e.count}, `
                    })
                    radioStr += `#${item.id} ${item.name} joined & ordered ${foodStr};`
                    radioStr_chn += `#${item.id} ${item.name} 入店 并 下单 ${foodStr_chn};`
                    radioStr_kor += `#${item.id} ${item.name} 入店 并 下单 ${foodStr_kor};`
                    otherOrders.push({
                        id: item.id,
                        name: item.name,
                        foods: foods,
                    })
                }
                // this.set_radio_str(radioStr)
                this.radioStr = {
                    str: radioStr,
                    str_chn: radioStr_chn,
                    str_kor: radioStr_kor,
                }
                this.otherOrders = otherOrders
            })
        },
        ...mapMutations({}),
    },
}
</script>
<style lang="scss" scoped>
.container {
    position: relative;
}
.header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    // width: 100%;
    height: 65px;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAwwAAADiBAMAAAAR910nAAAAGFBMVEUNqlE9uE8UrFA3t08xtVAps1AarlAisFC5cK/VAAAEDElEQVR42uTasU0EQBDF0G1hxXGk1EBAjKiAEiiB/iPa+LZ/C0+awJpzO/s+u7uZvZ7h3cx+z/BuZR9neTey6ZPUYfg707uNfZ7t3cQe72d7N7GfM75b2POs7wb2sn6SGgxfZ37Xv7ezv6vfy3TFyDAsh9UOw3jFqDCMV4wIw3ZYrTDMV4wGw3zFSDCsh9UGA6BiFBgoJ8nNQKgYfgZExfAzICqGnoFRMfQMoJMkZoBUDDnD46B2pWOEVTsDpmKoGShhVc7AqRhmBlDFEDNwwqqaAfAeFmDgnSQjAymsihlIYdXLgAqrXgZYxZAysMKqlQFXMZwMuIqhZKCFVScDsGIYGagnycVArBg+BmTF8DEgK4aOgVkxdAzgkyRigFYMGQPsPczKwAyrNgZsxVAxUMOqjIFbMUwM4IohYuCGVRUD8D1MyMA/SQYGclgVMZDDqocBHVY9DPCKIWFgh1ULAzysWhjwFUPBQA+rDgZBxTAwWE4Sm8FQMfgMiorBZ1BUDDyDo2LgGUQnCcwgqRhwBkvFgDM4wiqdQVMx0Az49zAHg6dikBlEFQPM4AmraAbBe5iAwXeSiAymsApmMIVVLoMqrHIZZBUDyuAKq1QGWVilMugqBpLheaS7pAkrBpHBepJYDMaKwWNQVgweg7Ji4BicFQPHID5JIAZpxYAxWCsGjMEZVmkMtvcwJoPuPYzJ4K0YJAZxxQAxeMMqikH4HgZk8J8kAoM5rIIYzGGVw6AOqxwGecWAMLjDKoVBHlYpDPqKgWDQvoehGAIVg8BQOUnbDIWKsc+QqBj7DImKMc/QqBjzDKGTNMwQqRjjDJWKMc7QCKvrDPb3MAaD/j2MwdCpGMsMoYoxzNAJq9MMgfcwAEPvJE0ypCrGLEMprO4ypMLqLkOsYowytMLqKkMsrK4y5CrGJEPmPWyaIVgxFhmqJ2mLoVgx9hhK72HDDMmKMcfQrBhzDOGTNMQQrRhjDNWKMcbQDKv/7dqxCQAADMOw/7/uCx0FcV8QdDDRGNbmYSbD3DzMZNitGBLDcMWAGHbDKsUwOA8DGXpJBMN0xWAYlsOqwzAdVh2G8YqBMGyHVYVhvmIYDPMVg2CYnYdRDFUMgqGXJDBUMQSG5XkYxFDFEBiqGARDL0lgqGIIDOPzMIWhsCowrM/DDIbCKsFQxRAYqhgCQ2GVYGgeJjD0kgiGKobAUFgVGAqrBEMVQ2AorAoMVQyCoYohMDQPExiqGARDL0lgqGIIDM3DCIYqhsBQxSAYekkCQxVDYGgeRjAUVgWG5mECQ2GVYKhiCAxVDIGhsEowNA8TGHpJBEMVQ2AorAoMhVWCoYohMBRWBYYqBsFQxRAYmocJDFUMgqGXJDBUMQSG5mEEQxXjfQfHTMCez/ccmAAAAABJRU5ErkJggg==);
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 28px;
    .iconfont {
        font-size: 30px;
    }
}
.broadcast {
    height: 22px;
    background: #f6bf9a;
    color: #ea5d00;
    // font-weight: 300;
    font-size: 10px;
    line-height: 22px;
}

.column {
    padding: 16px 22px;
    &.gery {
        background: #f4efed;
    }
    .title {
        margin-bottom: 12px;
        color: #93634c;
        font-weight: 700;
        font-size: 14px;
    }
    &.success {
        margin-bottom: 34px;
        text-align: center;
        .icon {
            .iconfont {
                color: #3db84f;
                font-weight: bold;
                font-size: 30px;
            }
        }
        .text {
            margin-top: 8px;
            color: #3db84f;
            font-weight: 700;
            font-size: 20px;
        }
        .save-more {
            margin-top: 16px;
        }
        .btns {
            display: flex;
            justify-content: center;
            margin-top: 15px;
            .share-btn {
                display: flex;
                align-content: center;
                padding: 0 6px;
                height: 34px;
                border: 1px solid #f64f00;
                border-radius: 7px;
                color: #f64f00;
                font-weight: 700;
                line-height: 34px;
                .iconfont {
                    margin-right: 4px;
                    font-size: 18px;
                }
            }
            .btn-left {
                margin-right: 20px;
            }
        }
        .end {
            margin-top: 17px;
            text-align: center;
            .countdown {
                margin-left: 6px;
                padding: 4px 6px;
                border-radius: 6px;
                background: #ea5d00;
                .van-countdown {
                    display: inline-block;
                    color: #fff;
                    font-size: 14px;
                }
            }
        }
    }
    .info {
        display: flex;
        // align-items: center;
        margin-bottom: 12px;
        color: #93634c;
        line-height: 14px;
        .label {
            flex-shrink: 0;
            margin-right: 10px;
            width: 100px;
            text-align: right;
        }
        .text {
            font-weight: 700;
            font-size: 14px;
            &.text-samll {
                font-weight: normal;
            }
        }
        .phone {
            display: flex;
            .prefix {
                margin-right: 8px;
                width: 35px;
                height: 35px;
                border: 1px solid #3db84f;
                border-radius: 7px;
                color: #3db84f;
                text-align: center;
                line-height: 35px;
            }
            .border-input {
                width: 172px;
            }
        }
        .tip {
            display: flex;
            height: 40px;
            border: 1px solid #44ba56;
            border-radius: 6px;
            color: #44ba56;
            line-height: 40px;
            .tip-item {
                width: 47px;
                border-radius: 4px;
                text-align: center;
                &.active {
                    background: #3db84f;
                    color: #fff;
                }
            }
        }
        .pay {
            display: flex;
            .item {
                display: flex;
                align-items: center;
                margin-right: 5px;
                padding: 0 3px;
                height: 40px;
                border: 1px solid #44ba56;
                border-radius: 4px;
                color: #44ba56;
                line-height: 40px;
                .iconfont {
                    margin-right: 4px;
                }
                .icon-dollar {
                    width: 20px;
                    height: 20px;
                    border-radius: 4px;
                    background: #44ba56;
                    color: #def2e0;
                    text-align: center;
                    line-height: 20px;
                }
                &.active {
                    background: #44ba56;
                    color: #fff;
                    .icon-dollar {
                        background: #def2e0;
                        color: #44ba56;
                    }
                    .icon-card {
                        color: #def2e0;
                    }
                }
            }
        }
    }
    .order {
        padding: 12px 24px;
        border-radius: 7px 7px 0 0;
        background: #f6f6f6;
        .item {
            margin-bottom: 12px;
            &:last-child {
                margin-bottom: 0px;
            }
        }
        .food {
            display: flex;
            // font-size: 14px;
            .name {
                font-weight: 700;
            }
            .num {
                margin-right: 4px;
                color: #44ba56;
                font-weight: 700;
            }
            .remark {
                color: #999999;
                font-size: 10px;
            }
            .price {
                margin-left: auto;
                font-weight: 700;
            }
        }
        .addon {
            margin-top: 6px;
            padding-left: 10px;
            color: #999999;
        }
        .note {
            margin-top: 6px;
            padding-left: 10px;
            color: #999999;
        }
    }
    &.store-column {
        margin-top: 30px;
        border-top: 1px solid #93634c;
        .store {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0px 0 26px;
            height: 44px;

            .logo {
                margin-right: 12px;
                width: 44px;
                height: 44px;
            }
            .store-name {
                color: #0daa51;
                font-weight: 700;
                font-size: 16px;
            }
        }
        .other {
            margin: 24px 0px 0;
            padding: 16px 19px 0;
            border-radius: 12px;
            background: #e7f2df;
            .title {
                margin-bottom: 10px;
                color: #3db84f;
                font-weight: 700;
                font-size: 14px;
            }
            .order {
                margin-bottom: 6px;
                padding: 0;
                background: #e7f2df;
            }
            .order:last-child {
                .border {
                    background: #e7f2df;
                }
            }
            .serial {
                display: flex;
                margin-bottom: 6px;
                color: #999;
                .num {
                    width: 40px;
                }
            }
            .food {
                margin-bottom: 4px;
                margin-left: 40px;
                color: #999;
            }

            .border {
                margin-top: 8px;
                width: 276px;
                height: 1px;
                background: rgba(151, 151, 151, 0.43);
            }
        }
    }
}

.total {
    padding: 0px 24px 9px;
    border-radius: 0 0 7px 7px;
    background: #f6f6f6;
    // box-shadow: 0px 0px 10px rgba(116, 116, 116, 0.2);
    .row {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin-bottom: 4px;
        // color: #8f5f49;
        font-size: 14px;
        .content {
            margin-left: auto;
            &.no-right {
                margin-left: 0;
            }
            &.savin {
                margin-right: 6px;
                margin-left: auto;
                padding: 2px 6px;
                background: #e9dfda;
                color: #da352e;
                font-style: italic;
            }
        }
        &.row-line {
            padding-top: 12px;
            border-top: 1px solid #979797;
        }
    }
}
.process-bar {
    display: flex;
    width: 100%;
    height: 48px;
    border-top: 1px solid #93634c;
    border-bottom: 1px solid #93634c;
    color: #93634c;
    &.green {
        background: #0daa51;
    }
    .item {
        position: relative;
        display: flex;
        align-items: center;
        width: 25%;
        text-align: center;
        .blank {
            width: 24px;
        }
        &.item-first {
            width: 20%;
        }
        &.active {
            background: #0daa51;
            color: #fff;
        }
    }
    .process-text {
        flex-grow: 1;
        padding-left: 26px;
        font-size: 10px;
        &.first {
            padding-left: 0;
        }
    }
    .triangle {
        position: absolute;
        left: -1px;
        width: 0;
        height: 0;
        border: 24px solid transparent;
        border-left: 24px solid #0daa51;
    }
    .arrow {
        position: absolute;
        left: -18px;
        width: 34px;
        height: 34px;
        border-top: 1px solid #93634c;
        border-right: 1px solid #93634c;
        transform: rotate(45deg);
    }
}
</style>
