import axiosApi from '../../kits/axiosApi'
import Vue from 'vue'

// initial state
const state = {
    is_loading: true,
}

// getters
const getters = {}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
