<template>
    <div class="plus_minus">
        <template v-if="count > 0">
            <div class="cube delete" v-show="count == 1" @click.stop="minusCb">
                <span class="iconfont icon-error"></span>
            </div>
            <div :class="[count == 0 ? 'disable' : '', 'cube', 'minus']" @click.stop="minusCb" v-show="count == 0 || count > 1">
                <span class="iconfont icon-minus"></span>
            </div>
            <div class="number">{{ count }}</div>
        </template>
        <div class="cube plus" @click.stop="plusCb">
            <span class="iconfont icon-plus"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'plus_minus',
    props: {
        minusCb: Function,
        plusCb: Function,
        count: Number,
        test: String,
    },
    components: {},
    data() {
        return {}
    },
}
</script>

<style lang="scss" scoped>
.plus_minus {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .cube {
        width: 26px;
        height: 26px;
        border-radius: 4px;
        background: #3db84f;
        color: #fff;
        text-align: center;
        line-height: 26px;
        .iconfont {
            font-size: 14px;
        }
        &.disable {
            background: #999999;
        }
    }
    .minus {
        background: #999999;
    }
    .delete {
        background: #da352e;
    }
    .number {
        margin: 0 6px;
        min-width: 16px;
        color: #999;
        text-align: center;
        font-size: 22px;
    }
}
</style>
