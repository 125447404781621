<template>
    <div>
        <h1>{{ msg }}</h1>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
    name: 'welcome',
    data() {
        return {
            msg: 'Please open the correct url.',
        }
    },
    created() {
        let { type } = this.$route.params || 0
        if (type == 1) {
            this.msg = 'The page you’re looking for can’t be found.'
        }
    },
    mounted() {},
    computed: {
        ...mapGetters({
            loading: 'app/getLoading',
        }),
    },
    methods: {
        ...mapActions({
            initData: 'app/initData',
        }),
        ...mapMutations({}),
    },
}
</script>
<style scoped>
h1 {
    margin: 100px 30px 0;
    text-align: center;
    font-size: 26px;
    line-height: 45px;
}
</style>
